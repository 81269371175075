import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const CustomOverlayTrigger = ({ content, children, placement }) => {
  return (
    <OverlayTrigger
      overlay={<Tooltip className="position-fixed">{content}</Tooltip>}
      placement={placement}
    >
      {children}
    </OverlayTrigger>
  );
};

export default CustomOverlayTrigger;
