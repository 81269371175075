import React, { useState, useEffect } from 'react';
import _ from 'lodash'
import { useLocation } from 'react-router-dom';

import * as Constants from '../../../../../../helpers/constants';
import LineChart from '../../../../../widgets/linechart';
import FiltersComponent from '../../../../../widgets/filters';
import * as FiltersConstants from '../../../../../widgets/filters/constants'

import '../analytics.css'
import { ApiHelper } from '../../../../../../helpers/apihelper';

const Prospect = ({ client, userId }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const co_user_id = queryParams.get('user_id') || queryParams.get('org_id') || userId;

  const initialstate = {
    filters: {
      date_range: {
        filter_label: 'Date',
        label: 'Last 7 days (Including Today)',
        value: 'ps_last_7_days_including_today'
      }
    },
  }

  const [prospectData, setProspectData] = useState({
    totalProspects: 0,
    totalCredits: 0,
    emailContacts: 0,
    phoneContacts: 0,
  });

  const [lineChartData, setLineChartData] = useState({
    labels: [],
    breakdown: [],
    values: [],
  });

  const [state, setState] = useState(initialstate)
  useEffect(() => {
    const filter = {
      org_id: null,
      date_range: state.filters.date_range?.value ? state.filters.date_range?.value : null,
      member_id: null,
      user_id: co_user_id,
    };

    const requestQueryParams = {
      user_id: co_user_id,
      filters: JSON.stringify(filter),
    };

    let queryParams = _.pick(requestQueryParams, ApiHelper(client, 'PROSPECT_REPORTS').API_QUERY)

    ApiHelper(client, 'PROSPECT_REPORTS').API(client, queryParams)
      .then((response) => {
        if (response && response.status === Constants.CO_API.SUCCESS) {
          const stats = response.data.user_stats[0].stats;

          // Update state with the total
          setProspectData({
            totalProspects: stats.reduce((total, stat) => total + stat.total_billable_lead, 0),
            totalCredits: stats.reduce((total, stat) => total + stat.total_credits_charged, 0),
            emailContacts: stats.reduce((total, stat) => total + stat.total_billable_email_contact, 0),
            phoneContacts: stats.reduce((total, stat) => total + stat.total_billable_phone_contact, 0),
          });

          // Update LineChart data
          setLineChartData({
            labels: stats.map((stat) => stat.date),
            breakdown: response.data.breakdown,
            values: [
              {
                label: 'Email Contacts',
                data: stats.map((stat) => stat.person_email_total),
              },
              {
                label: 'Phone Contacts',
                data: stats.map((stat) => stat.person_phone_total),
              },
              {
                label: 'Total Prospects',
                data: stats.map((stat) => stat.total_lead),
              },
              {
                label: 'Total Credits',
                data: stats.map((stat) => stat.total_credits_charged),
              },
            ],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [co_user_id, state.filters, client]);


  const onSetFilter = (filters) => {
    setState(prevState => ({ ...prevState, filters: { ...filters } }))
  }


  return (
    <>
      <FiltersComponent
        filterState={state.filters}
        onSetFilters={onSetFilter}
        filters={FiltersConstants.ANALYTICS[client].filters}
        filtersToInclude={FiltersConstants.FILTERS_TO_INCLUDE[client].ANALYTICS}
        className='analytics-filter'
        resetFilters={FiltersConstants.ANALYTICS[client].onResetFilters}
      />
      <div className="overview-container border rounded p-3">
        <div className="row">
          {/* Left Box */}
          <div className="col text-center">
            <div className='total-verified fw-bold fs-5'>
              <p>Total Prospects</p>
              <p className='text-orange'>{prospectData.totalProspects}</p>
            </div>
            <div className='total-verified fw-bold fs-5'>
              <p>Total Credits</p>
              <p className='text-orange'>{prospectData.totalCredits}</p>
            </div>
            <div className='total-verified fw-bold fs-5'>
              <p>Email Contacts</p>
              <p className='text-orange'>{prospectData.emailContacts}</p>
            </div>
            <div className='total-verified fw-bold fs-5'>
              <p>Phone Contacts</p>
              <p className='text-orange'>{prospectData.phoneContacts}</p>
            </div>
          </div>
          {/* Separator */}
          <div className="col-1 border-start"></div>
          {/* Right Box */}
          <div className="col-8 text-center analytics-chart">
            <LineChart
              graphData={lineChartData}
              width={300}
              height={250}
              position="bottom"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Prospect;
