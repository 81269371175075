
export function formatNumberToXDecimalPlace(number, x) {
  const DOT = '.'
  number = Math.round(number * 1e10) / 1e10
  number = number.toString()
  let indexOfDot = number.indexOf(DOT)

  if (indexOfDot !== -1) {
    number = number.slice(0, indexOfDot + x + 1)
  }

  return +number
}

export const calculateAddonAmount = (product, quantity) => {
  const totalCount = product.limits.count;
  const price = product.price.total_price;
  const totalQuantity = quantity / totalCount;
  return formatNumberToXDecimalPlace(price * totalQuantity, 2);
};