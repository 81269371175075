import React from 'react';
import { CLIENTS } from '../../../helpers/constants';

export const ORG_BASIC_USER_INFO = {
  [CLIENTS.CLEAROUT.NAME]: {
    LEFT_TABLE: [
      {
        label: 'Website Url',
        accessorKey: 'org_website_url',
        formatter: 'orgWebsiteFormatter',
      },
      {
        label: 'Plan',
        accessorKey: 'plan.name',
        formatter: 'orgPlanFormatter',
      },
      {
        label: 'Account Status',
        accessorKey: 'status',
        formatter: 'accountStatusFormatter',
      },
    ],
    RIGHT_TABLE: [
      {
        label: 'Email',
        accessorKey: 'email',
        formatter: 'orgEmailFormatter',
      },
      {
        label: 'Phone',
        accessorKey: 'org_tel',
        formatter: 'orgPhoneFormatter',
      },
      {
        label: 'TimeZone',
        accessorKey: 'locale.timezone',
        formatter: 'timezoneFormatter',
      },
    ]

  },
  [CLIENTS.CLEAROUTPHONE.NAME]: {
    LEFT_TABLE: [
      {
        label: 'Website Url',
        accessorKey: 'org_website_url',
        formatter: 'orgWebsiteFormatter',
      },
      {
        label: 'Plan',
        accessorKey: 'plan.name',
        formatter: 'orgPlanFormatter',
      },
      {
        label: 'Account Status',
        accessorKey: 'status',
        formatter: 'accountStatusFormatter',
      },
    ],
    RIGHT_TABLE: [
      {
        label: 'Email',
        accessorKey: 'email',
        formatter: 'orgEmailFormatter',
      },
      {
        label: 'Phone',
        accessorKey: 'org_tel',
        formatter: 'orgPhoneFormatter',
      },
      {
        label: 'TimeZone',
        accessorKey: 'locale.timezone',
        formatter: 'timezoneFormatter',
      },
    ]

  },
  [CLIENTS.SPOKESLY_CLEAROUT.NAME]: {
    LEFT_TABLE: [
      {
        label: 'Website Url',
        accessorKey: 'org_website_url',
        formatter: 'orgWebsiteFormatter',
      },
      {
        label: 'Plan',
        accessorKey: 'plan.name',
        formatter: 'orgPlanFormatter',
      },
      {
        label: 'Account Status',
        accessorKey: 'status',
        formatter: 'accountStatusFormatter',
      },
    ],
    RIGHT_TABLE: [
      {
        label: 'Email',
        accessorKey: 'email',
        formatter: 'orgEmailFormatter',
      },
      {
        label: 'Phone',
        accessorKey: 'org_tel',
        formatter: 'orgPhoneFormatter',
      },
      {
        label: 'TimeZone',
        accessorKey: 'locale.timezone',
        formatter: 'timezoneFormatter',
      },
    ]

  },
  [CLIENTS.EU_CLEAROUT.NAME]: {
    LEFT_TABLE: [
      {
        label: 'Website Url',
        accessorKey: 'org_website_url',
        formatter: 'orgWebsiteFormatter',
      },
      {
        label: 'Plan',
        accessorKey: 'plan.name',
        formatter: 'orgPlanFormatter',
      },
      {
        label: 'Account Status',
        accessorKey: 'status',
        formatter: 'accountStatusFormatter',
      },
    ],
    RIGHT_TABLE: [
      {
        label: 'Email',
        accessorKey: 'email',
        formatter: 'orgEmailFormatter',
      },
      {
        label: 'Phone',
        accessorKey: 'org_tel',
        formatter: 'orgPhoneFormatter',
      },
      {
        label: 'TimeZone',
        accessorKey: 'locale.timezone',
        formatter: 'timezoneFormatter',
      },
    ]

  },
  [CLIENTS.EP_CLEAROUT.NAME]: {
    LEFT_TABLE: [
      {
        label: 'Website Url',
        accessorKey: 'org_website_url',
        formatter: 'orgWebsiteFormatter',
      },
      {
        label: 'Plan',
        accessorKey: 'plan.name',
        formatter: 'orgPlanFormatter',
      },
      {
        label: 'Account Status',
        accessorKey: 'status',
        formatter: 'accountStatusFormatter',
      },
    ],
    RIGHT_TABLE: [
      {
        label: 'Email',
        accessorKey: 'email',
        formatter: 'orgEmailFormatter',
      },
      {
        label: 'Phone',
        accessorKey: 'org_tel',
        formatter: 'orgPhoneFormatter',
      },
      {
        label: 'TimeZone',
        accessorKey: 'locale.timezone',
        formatter: 'timezoneFormatter',
      },
    ]

  },
  [CLIENTS.CAMS_CLEAROUT.NAME]: {
    LEFT_TABLE: [
      {
        label: 'Website Url',
        accessorKey: 'org_website_url',
        formatter: 'orgWebsiteFormatter',
      },
      {
        label: 'Plan',
        accessorKey: 'plan.name',
        formatter: 'orgPlanFormatter',
      },
      {
        label: 'Account Status',
        accessorKey: 'status',
        formatter: 'accountStatusFormatter',
      },
    ],
    RIGHT_TABLE: [
      {
        label: 'Email',
        accessorKey: 'email',
        formatter: 'orgEmailFormatter',
      },
      {
        label: 'Phone',
        accessorKey: 'org_tel',
        formatter: 'orgPhoneFormatter',
      },
      {
        label: 'TimeZone',
        accessorKey: 'locale.timezone',
        formatter: 'timezoneFormatter',
      },
    ]

  },
  [CLIENTS.PEPIPOST_CLEAROUT.NAME]: {
    LEFT_TABLE: [
      {
        label: 'Website Url',
        accessorKey: 'org_website_url',
        formatter: 'orgWebsiteFormatter',
      },
      {
        label: 'Plan',
        accessorKey: 'plan.name',
        formatter: 'orgPlanFormatter',
      },
      {
        label: 'Account Status',
        accessorKey: 'status',
        formatter: 'accountStatusFormatter',
      },
    ],
    RIGHT_TABLE: [
      {
        label: 'Email',
        accessorKey: 'email',
        formatter: 'orgEmailFormatter',
      },
      {
        label: 'Phone',
        accessorKey: 'org_tel',
        formatter: 'orgPhoneFormatter',
      },
      {
        label: 'TimeZone',
        accessorKey: 'locale.timezone',
        formatter: 'timezoneFormatter',
      },
    ]

  },
};


export const ACTION_BUTTONS = {
  [CLIENTS.CLEAROUT.NAME]: ['logout_sessions', 'block_user', 'unblock_user', 'delete_user', 'signup_link', 'forgot_password_link'],
  [CLIENTS.CLEAROUTPHONE.NAME]: ['logout_sessions', 'block_user', 'unblock_user', 'signup_link', 'forgot_password_link', 'cop_view_stats', 'delete_user'],
  [CLIENTS.SPOKESLY_CLEAROUT.NAME]: ['logout_sessions', 'block_user', 'unblock_user', 'delete_user', 'signup_link', 'forgot_password_link'],
  [CLIENTS.EU_CLEAROUT.NAME]: ['logout_sessions', 'block_user', 'unblock_user', 'delete_user', 'signup_link', 'forgot_password_link'],
  [CLIENTS.EP_CLEAROUT.NAME]: ['logout_sessions', 'block_user', 'unblock_user', 'delete_user', 'signup_link', 'forgot_password_link'],
  [CLIENTS.CAMS_CLEAROUT.NAME]: ['logout_sessions', 'block_user', 'unblock_user', 'delete_user', 'signup_link', 'forgot_password_link'],
  [CLIENTS.PEPIPOST_CLEAROUT.NAME]: ['logout_sessions', 'block_user', 'unblock_user', 'delete_user', 'signup_link', 'forgot_password_link'],
}

export const QUICK_LINKS = {
  [CLIENTS.CLEAROUT.NAME]: ['user_activities', 'matomo_activities', 'email_verifier_lists', 'email_finder_lists', 'prospect_lists'],
  [CLIENTS.CLEAROUTPHONE.NAME]: ['user_activities', 'matomo_activities', 'clearout_phone_lists'],
  [CLIENTS.SPOKESLY_CLEAROUT.NAME]: ['user_activities', 'matomo_activities'],
  [CLIENTS.EU_CLEAROUT.NAME]: ['user_activities', 'matomo_activities'],
  [CLIENTS.EP_CLEAROUT.NAME]: ['user_activities', 'matomo_activities'],
  [CLIENTS.CAMS_CLEAROUT.NAME]: ['user_activities', 'matomo_activities'],
  [CLIENTS.PEPIPOST_CLEAROUT.NAME]: ['user_activities', 'matomo_activities'],
}

export const TAB_KEY_AND_LABLE_MAPPING = {
  credits_limits: { key: 'credits_limits', label: 'Credits & Limits' },
  plans: { key: 'plans', label: 'Plans' },
  subscriptions: { key: 'subscriptions', label: 'Subscriptions' },
  invoices_receipts: { key: 'invoices_receipts', label: 'Invoices & Receipts' },
  analytics: { key: 'analytics', label: 'Analytics' },
}

export const TAB_SECTION = {
  clearout: ['credits_limits', 'analytics'],
  clearoutphone: ['credits_limits', 'analytics'],
  spokesly_clearout: ['credits_limits', 'analytics'],
  eu_clearout: ['credits_limits', 'analytics'],
  ep_clearout: ['credits_limits', 'analytics'],
  cams_clearout: ['credits_limits', 'analytics'],
  pepipost_clearout: ['credits_limits', 'analytics'],
}

export const CREDITS_LIMITS_TAB = {
  [CLIENTS.CLEAROUT.NAME]: {
    CREDITS_SECTION: [
      {
        label: 'Total Remaining Credits',
        accessorKey: 'credits.total',
        formatter: 'totalRemainingCreditsFormatter',
      },
      {
        label: 'Pay As You Go Credits',
        accessorKey: 'credits.available',
        formatter: 'payAsYouGoCreditsFormatter',
      },
      {
        label: 'Subscription Credits',
        accessorKey: 'credits.subs',
        formatter: 'subscriptionCreditsFormatter',
      },
      {
        label: 'Recur Credits',
        accessorKey: 'credits.recur',
        formatter: 'recurCreditsFormatter',
      },
    ],
    LIMITS_SECTION: [
      {
        label: 'API Rate Limit ',
        // accessorKey: 'credits.total',
        formatter: 'apiRateLimitFormatter',
      },
      {
        label: 'Instant Email Verify',
        accessorKey: 'limits.instant_email_verify_limit.maxRequests',
        formatter: 'instantEmailVerifyFormatter',
      },
      {
        label: 'Instant Email Finder',
        accessorKey: 'limits.instant_email_finder_limit.maxRequests',
        formatter: 'instantEmailFinderFormatter',
      },
      {
        label: 'Daily Limit',
        // accessorKey: 'limits.instant_email_finder_limit.maxRequests',
        formatter: 'dailyLimitFormatter',
      },
      {
        label: 'Remaining daily limit',
        // accessorKey: 'credits.available_daily_limit ',
        formatter: 'remainingDailyLimitFormatter',
      },
      {
        label: 'Allowed daily limit',
        // accessorKey: 'credits.available_daily_limit ',
        formatter: 'allowedDailyLimitFormatter',
      },
    ]

  },
  [CLIENTS.CLEAROUTPHONE.NAME]: {
    CREDITS_SECTION: [
      {
        label: 'Total Remaining Credits',
        accessorKey: 'credits.total',
        formatter: 'totalRemainingCreditsFormatter',
      },
      {
        label: 'Pay As You Go Credits',
        accessorKey: 'credits.available',
        formatter: 'payAsYouGoCreditsFormatter',
      },
      {
        label: 'Subscription Credits',
        accessorKey: 'credits.subs',
        formatter: 'subscriptionCreditsFormatter',
      },
      {
        label: 'Recur Credits',
        accessorKey: 'credits.recur',
        formatter: 'recurCreditsFormatter',
      },
    ],
    LIMITS_SECTION: [
      {
        label: 'API Rate Limit ',
        // accessorKey: 'credits.total',
        formatter: 'apiRateLimitFormatter',
      },
      {
        label: 'Instant Email Verify',
        accessorKey: 'limits.instant_email_verify_limit.maxRequests',
        formatter: 'instantEmailVerifyFormatter',
      },
      {
        label: 'Instant Email Finder',
        accessorKey: 'limits.instant_email_finder_limit.maxRequests',
        formatter: 'instantEmailFinderFormatter',
      },
      {
        label: 'Daily Limit',
        // accessorKey: 'limits.instant_email_finder_limit.maxRequests',
        formatter: 'dailyLimitFormatter',
      },
      {
        label: 'Remaining daily limit',
        // accessorKey: 'credits.available_daily_limit ',
        formatter: 'remainingDailyLimitFormatter',
      },
      {
        label: 'Allowed daily limit',
        // accessorKey: 'credits.available_daily_limit ',
        formatter: 'allowedDailyLimitFormatter',
      },
    ]
  },
  [CLIENTS.SPOKESLY_CLEAROUT.NAME]: {
    CREDITS_SECTION: [
      {
        label: 'Total Remaining Credits',
        accessorKey: 'credits.total',
        formatter: 'totalRemainingCreditsFormatter',
      },
      {
        label: 'Pay As You Go Credits',
        accessorKey: 'credits.available',
        formatter: 'payAsYouGoCreditsFormatter',
      },
      {
        label: 'Subscription Credits',
        accessorKey: 'credits.subs',
        formatter: 'subscriptionCreditsFormatter',
      },
      {
        label: 'Recur Credits',
        accessorKey: 'credits.recur',
        formatter: 'recurCreditsFormatter',
      },
    ],
    LIMITS_SECTION: [
      {
        label: 'API Rate Limit ',
        // accessorKey: 'credits.total',
        formatter: 'apiRateLimitFormatter',
      },
      {
        label: 'Instant Email Verify',
        accessorKey: 'limits.instant_email_verify_limit.maxRequests',
        formatter: 'instantEmailVerifyFormatter',
      },
      {
        label: 'Instant Email Finder',
        accessorKey: 'limits.instant_email_finder_limit.maxRequests',
        formatter: 'instantEmailFinderFormatter',
      },
      {
        label: 'Daily Limit',
        // accessorKey: 'limits.instant_email_finder_limit.maxRequests',
        formatter: 'dailyLimitFormatter',
      },
      {
        label: 'Remaining daily limit',
        // accessorKey: 'credits.available_daily_limit ',
        formatter: 'remainingDailyLimitFormatter',
      },
      {
        label: 'Allowed daily limit',
        // accessorKey: 'credits.available_daily_limit ',
        formatter: 'allowedDailyLimitFormatter',
      },
    ]

  },
  [CLIENTS.EU_CLEAROUT.NAME]: {
    CREDITS_SECTION: [
      {
        label: 'Total Remaining Credits',
        accessorKey: 'credits.total',
        formatter: 'totalRemainingCreditsFormatter',
      },
      {
        label: 'Pay As You Go Credits',
        accessorKey: 'credits.available',
        formatter: 'payAsYouGoCreditsFormatter',
      },
      {
        label: 'Subscription Credits',
        accessorKey: 'credits.subs',
        formatter: 'subscriptionCreditsFormatter',
      },
      {
        label: 'Recur Credits',
        accessorKey: 'credits.recur',
        formatter: 'recurCreditsFormatter',
      },
    ],
    LIMITS_SECTION: [
      {
        label: 'API Rate Limit ',
        // accessorKey: 'credits.total',
        formatter: 'apiRateLimitFormatter',
      },
      {
        label: 'Instant Email Verify',
        accessorKey: 'limits.instant_email_verify_limit.maxRequests',
        formatter: 'instantEmailVerifyFormatter',
      },
      {
        label: 'Instant Email Finder',
        accessorKey: 'limits.instant_email_finder_limit.maxRequests',
        formatter: 'instantEmailFinderFormatter',
      },
      {
        label: 'Daily Limit',
        // accessorKey: 'limits.instant_email_finder_limit.maxRequests',
        formatter: 'dailyLimitFormatter',
      },
      {
        label: 'Remaining daily limit',
        // accessorKey: 'credits.available_daily_limit ',
        formatter: 'remainingDailyLimitFormatter',
      },
      {
        label: 'Allowed daily limit',
        // accessorKey: 'credits.available_daily_limit ',
        formatter: 'allowedDailyLimitFormatter',
      },
    ]

  },
  [CLIENTS.EP_CLEAROUT.NAME]: {
    CREDITS_SECTION: [
      {
        label: 'Total Remaining Credits',
        accessorKey: 'credits.total',
        formatter: 'totalRemainingCreditsFormatter',
      },
      {
        label: 'Pay As You Go Credits',
        accessorKey: 'credits.available',
        formatter: 'payAsYouGoCreditsFormatter',
      },
      {
        label: 'Subscription Credits',
        accessorKey: 'credits.subs',
        formatter: 'subscriptionCreditsFormatter',
      },
      {
        label: 'Recur Credits',
        accessorKey: 'credits.recur',
        formatter: 'recurCreditsFormatter',
      },
    ],
    LIMITS_SECTION: [
      {
        label: 'API Rate Limit ',
        // accessorKey: 'credits.total',
        formatter: 'apiRateLimitFormatter',
      },
      {
        label: 'Instant Email Verify',
        accessorKey: 'limits.instant_email_verify_limit.maxRequests',
        formatter: 'instantEmailVerifyFormatter',
      },
      {
        label: 'Instant Email Finder',
        accessorKey: 'limits.instant_email_finder_limit.maxRequests',
        formatter: 'instantEmailFinderFormatter',
      },
      {
        label: 'Daily Limit',
        // accessorKey: 'limits.instant_email_finder_limit.maxRequests',
        formatter: 'dailyLimitFormatter',
      },
      {
        label: 'Remaining daily limit',
        // accessorKey: 'credits.available_daily_limit ',
        formatter: 'remainingDailyLimitFormatter',
      },
      {
        label: 'Allowed daily limit',
        // accessorKey: 'credits.available_daily_limit ',
        formatter: 'allowedDailyLimitFormatter',
      },
    ]

  },
  [CLIENTS.PEPIPOST_CLEAROUT.NAME]: {
    CREDITS_SECTION: [
      {
        label: 'Total Remaining Credits',
        accessorKey: 'credits.total',
        formatter: 'totalRemainingCreditsFormatter',
      },
      {
        label: 'Pay As You Go Credits',
        accessorKey: 'credits.available',
        formatter: 'payAsYouGoCreditsFormatter',
      },
      {
        label: 'Subscription Credits',
        accessorKey: 'credits.subs',
        formatter: 'subscriptionCreditsFormatter',
      },
      {
        label: 'Recur Credits',
        accessorKey: 'credits.recur',
        formatter: 'recurCreditsFormatter',
      },
    ],
    LIMITS_SECTION: [
      {
        label: 'API Rate Limit ',
        // accessorKey: 'credits.total',
        formatter: 'apiRateLimitFormatter',
      },
      {
        label: 'Instant Email Verify',
        accessorKey: 'limits.instant_email_verify_limit.maxRequests',
        formatter: 'instantEmailVerifyFormatter',
      },
      {
        label: 'Instant Email Finder',
        accessorKey: 'limits.instant_email_finder_limit.maxRequests',
        formatter: 'instantEmailFinderFormatter',
      },
      {
        label: 'Daily Limit',
        // accessorKey: 'limits.instant_email_finder_limit.maxRequests',
        formatter: 'dailyLimitFormatter',
      },
      {
        label: 'Remaining daily limit',
        // accessorKey: 'credits.available_daily_limit ',
        formatter: 'remainingDailyLimitFormatter',
      },
      {
        label: 'Allowed daily limit',
        // accessorKey: 'credits.available_daily_limit ',
        formatter: 'allowedDailyLimitFormatter',
      },
    ]

  },
  [CLIENTS.CAMS_CLEAROUT.NAME]: {
    CREDITS_SECTION: [
      {
        label: 'Total Remaining Credits',
        accessorKey: 'credits.total',
        formatter: 'totalRemainingCreditsFormatter',
      },
      {
        label: 'Pay As You Go Credits',
        accessorKey: 'credits.available',
        formatter: 'payAsYouGoCreditsFormatter',
      },
      {
        label: 'Subscription Credits',
        accessorKey: 'credits.subs',
        formatter: 'subscriptionCreditsFormatter',
      },
      {
        label: 'Recur Credits',
        accessorKey: 'credits.recur',
        formatter: 'recurCreditsFormatter',
      },
    ],
    LIMITS_SECTION: [
      {
        label: 'API Rate Limit ',
        // accessorKey: 'credits.total',
        formatter: 'apiRateLimitFormatter',
      },
      {
        label: 'Instant Email Verify',
        accessorKey: 'limits.instant_email_verify_limit.maxRequests',
        formatter: 'instantEmailVerifyFormatter',
      },
      {
        label: 'Instant Email Finder',
        accessorKey: 'limits.instant_email_finder_limit.maxRequests',
        formatter: 'instantEmailFinderFormatter',
      },
      {
        label: 'Daily Limit',
        // accessorKey: 'limits.instant_email_finder_limit.maxRequests',
        formatter: 'dailyLimitFormatter',
      },
      {
        label: 'Remaining daily limit',
        // accessorKey: 'credits.available_daily_limit ',
        formatter: 'remainingDailyLimitFormatter',
      },
      {
        label: 'Allowed daily limit',
        // accessorKey: 'credits.available_daily_limit ',
        formatter: 'allowedDailyLimitFormatter',
      },
    ]

  },
};

// export const INVOICE_AND_RECEIPT_TAB = {
//   clearout: {
//     SHOW_BUTTONS: ['generate_receipt'],
//   },
//   clearoutphone: {
//     SHOW_BUTTONS: ['generate_receipt'],
//   },
// }

export const BASIC_INFO = {
  clearout: {
    SHOW_BUTTONS: ['edit_email'],
  },
  clearoutphone: {
    SHOW_BUTTONS: ['edit_email'],
  },
  spokesly_clearout: {
    SHOW_BUTTONS: ['edit_email'],
  },
  eu_clearout: {
    SHOW_BUTTONS: ['edit_email'],
  },
  ep_clearout: {
    SHOW_BUTTONS: ['edit_email'],
  },
  cams_clearout: {
    SHOW_BUTTONS: ['edit_email'],
  },
  pepipost_clearout: {
    SHOW_BUTTONS: ['edit_email'],
  },
}

export const RECEIPT_TABLE_COLUMNS = {
  clearout: [
    {
      header: 'Quantity',
      accessorKey: 'quantity',
      formatter: 'receiptCreditFormatter',
    },
    {
      header: 'Description',
      accessorKey: 'description',
      formatter: 'receiptDescriptionFormatter',
    },
    {
      header: 'Billing',
      accessorKey: 'product_details.renewal',
      formatter: 'coReceiptBillingFormatter',
    },
    {
      header: 'Payment',
      accessorKey: 'payment_type',
      formatter: 'receiptPaymentFormatter',
    },
    {
      header:
        <>
          <span>Amount</span>
          <span>(In USD)</span>
        </>,
      accessorKey: 'amount',
      formatter: 'receiptAmountFormatter',
    },
    {
      header: 'Coupon Applied',
      accessorKey: 'coupon_details',
      formatter: 'receiptCouponFormatter',
    },
    {
      header: 'Purchased On',
      accessorKey: 'created_on',
      formatter: 'receiptPurchasedFormatter',
    },
    {
      header: 'Type',
      accessorKey: 'type',
      formatter: 'receiptTypeFormatter',
    },
    {
      header: 'Plan Id',
      accessorKey: 'product_details.product_id',
      formatter: 'receiptPlanIdFormatter',
    },
    {
      header: 'Payee Info',
      accessorKey: 'payee_details',
      formatter: 'coReceiptPayeeInfoFormatter',
    },
    {
      header: 'Payee Address',
      accessorKey: 'payee_details',
      formatter: 'coReceiptPayeeAddressFormatter',
    },
    {
      header: 'Download',
      accessorKey: '_id',
      formatter: 'invoiceAndReceiptDownloadFormatter'
    }
  ],
  clearoutphone: [
  ],
  spokesly_clearout: [
    {
      header: 'Quantity',
      accessorKey: 'quantity',
      formatter: 'receiptCreditFormatter',
    },
    {
      header: 'Description',
      accessorKey: 'description',
      formatter: 'receiptDescriptionFormatter',
    },
    {
      header: 'Billing',
      accessorKey: 'product_details.renewal',
      formatter: 'coReceiptBillingFormatter',
    },
    {
      header: 'Payment',
      accessorKey: 'payment_type',
      formatter: 'receiptPaymentFormatter',
    },
    {
      header:
        <>
          <span>Amount</span>
          <span>(In USD)</span>
        </>,
      accessorKey: 'amount',
      formatter: 'receiptAmountFormatter',
    },
    {
      header: 'Coupon Applied',
      accessorKey: 'coupon_details',
      formatter: 'receiptCouponFormatter',
    },
    {
      header: 'Purchased On',
      accessorKey: 'created_on',
      formatter: 'receiptPurchasedFormatter',
    },
    {
      header: 'Type',
      accessorKey: 'type',
      formatter: 'receiptTypeFormatter',
    },
    {
      header: 'Plan Id',
      accessorKey: 'product_details.product_id',
      formatter: 'receiptPlanIdFormatter',
    },
    {
      header: 'Payee Info',
      accessorKey: 'payee_details',
      formatter: 'coReceiptPayeeInfoFormatter',
    },
    {
      header: 'Payee Address',
      accessorKey: 'payee_details',
      formatter: 'coReceiptPayeeAddressFormatter',
    },
    {
      header: 'Download',
      accessorKey: '_id',
      formatter: 'invoiceAndReceiptDownloadFormatter'
    }
  ],
  eu_clearout: [
    {
      header: 'Quantity',
      accessorKey: 'quantity',
      formatter: 'receiptCreditFormatter',
    },
    {
      header: 'Description',
      accessorKey: 'description',
      formatter: 'receiptDescriptionFormatter',
    },
    {
      header: 'Billing',
      accessorKey: 'product_details.renewal',
      formatter: 'coReceiptBillingFormatter',
    },
    {
      header: 'Payment',
      accessorKey: 'payment_type',
      formatter: 'receiptPaymentFormatter',
    },
    {
      header:
        <>
          <span>Amount</span>
          <span>(In USD)</span>
        </>,
      accessorKey: 'amount',
      formatter: 'receiptAmountFormatter',
    },
    {
      header: 'Coupon Applied',
      accessorKey: 'coupon_details',
      formatter: 'receiptCouponFormatter',
    },
    {
      header: 'Purchased On',
      accessorKey: 'created_on',
      formatter: 'receiptPurchasedFormatter',
    },
    {
      header: 'Type',
      accessorKey: 'type',
      formatter: 'receiptTypeFormatter',
    },
    {
      header: 'Plan Id',
      accessorKey: 'product_details.product_id',
      formatter: 'receiptPlanIdFormatter',
    },
    {
      header: 'Payee Info',
      accessorKey: 'payee_details',
      formatter: 'coReceiptPayeeInfoFormatter',
    },
    {
      header: 'Payee Address',
      accessorKey: 'payee_details',
      formatter: 'coReceiptPayeeAddressFormatter',
    },
    {
      header: 'Download',
      accessorKey: '_id',
      formatter: 'invoiceAndReceiptDownloadFormatter'
    }
  ],
  ep_clearout: [
    {
      header: 'Quantity',
      accessorKey: 'quantity',
      formatter: 'receiptCreditFormatter',
    },
    {
      header: 'Description',
      accessorKey: 'description',
      formatter: 'receiptDescriptionFormatter',
    },
    {
      header: 'Billing',
      accessorKey: 'product_details.renewal',
      formatter: 'coReceiptBillingFormatter',
    },
    {
      header: 'Payment',
      accessorKey: 'payment_type',
      formatter: 'receiptPaymentFormatter',
    },
    {
      header:
        <>
          <span>Amount</span>
          <span>(In USD)</span>
        </>,
      accessorKey: 'amount',
      formatter: 'receiptAmountFormatter',
    },
    {
      header: 'Coupon Applied',
      accessorKey: 'coupon_details',
      formatter: 'receiptCouponFormatter',
    },
    {
      header: 'Purchased On',
      accessorKey: 'created_on',
      formatter: 'receiptPurchasedFormatter',
    },
    {
      header: 'Type',
      accessorKey: 'type',
      formatter: 'receiptTypeFormatter',
    },
    {
      header: 'Plan Id',
      accessorKey: 'product_details.product_id',
      formatter: 'receiptPlanIdFormatter',
    },
    {
      header: 'Payee Info',
      accessorKey: 'payee_details',
      formatter: 'coReceiptPayeeInfoFormatter',
    },
    {
      header: 'Payee Address',
      accessorKey: 'payee_details',
      formatter: 'coReceiptPayeeAddressFormatter',
    },
    {
      header: 'Download',
      accessorKey: '_id',
      formatter: 'invoiceAndReceiptDownloadFormatter'
    }
  ],
  cams_clearout: [
    {
      header: 'Quantity',
      accessorKey: 'quantity',
      formatter: 'receiptCreditFormatter',
    },
    {
      header: 'Description',
      accessorKey: 'description',
      formatter: 'receiptDescriptionFormatter',
    },
    {
      header: 'Billing',
      accessorKey: 'product_details.renewal',
      formatter: 'coReceiptBillingFormatter',
    },
    {
      header: 'Payment',
      accessorKey: 'payment_type',
      formatter: 'receiptPaymentFormatter',
    },
    {
      header:
        <>
          <span>Amount</span>
          <span>(In USD)</span>
        </>,
      accessorKey: 'amount',
      formatter: 'receiptAmountFormatter',
    },
    {
      header: 'Coupon Applied',
      accessorKey: 'coupon_details',
      formatter: 'receiptCouponFormatter',
    },
    {
      header: 'Purchased On',
      accessorKey: 'created_on',
      formatter: 'receiptPurchasedFormatter',
    },
    {
      header: 'Type',
      accessorKey: 'type',
      formatter: 'receiptTypeFormatter',
    },
    {
      header: 'Plan Id',
      accessorKey: 'product_details.product_id',
      formatter: 'receiptPlanIdFormatter',
    },
    {
      header: 'Payee Info',
      accessorKey: 'payee_details',
      formatter: 'coReceiptPayeeInfoFormatter',
    },
    {
      header: 'Payee Address',
      accessorKey: 'payee_details',
      formatter: 'coReceiptPayeeAddressFormatter',
    },
    {
      header: 'Download',
      accessorKey: '_id',
      formatter: 'invoiceAndReceiptDownloadFormatter'
    }
  ],
  pepipost_clearout: [
    {
      header: 'Quantity',
      accessorKey: 'quantity',
      formatter: 'receiptCreditFormatter',
    },
    {
      header: 'Description',
      accessorKey: 'description',
      formatter: 'receiptDescriptionFormatter',
    },
    {
      header: 'Billing',
      accessorKey: 'product_details.renewal',
      formatter: 'coReceiptBillingFormatter',
    },
    {
      header: 'Payment',
      accessorKey: 'payment_type',
      formatter: 'receiptPaymentFormatter',
    },
    {
      header:
        <>
          <span>Amount</span>
          <span>(In USD)</span>
        </>,
      accessorKey: 'amount',
      formatter: 'receiptAmountFormatter',
    },
    {
      header: 'Coupon Applied',
      accessorKey: 'coupon_details',
      formatter: 'receiptCouponFormatter',
    },
    {
      header: 'Purchased On',
      accessorKey: 'created_on',
      formatter: 'receiptPurchasedFormatter',
    },
    {
      header: 'Type',
      accessorKey: 'type',
      formatter: 'receiptTypeFormatter',
    },
    {
      header: 'Plan Id',
      accessorKey: 'product_details.product_id',
      formatter: 'receiptPlanIdFormatter',
    },
    {
      header: 'Payee Info',
      accessorKey: 'payee_details',
      formatter: 'coReceiptPayeeInfoFormatter',
    },
    {
      header: 'Payee Address',
      accessorKey: 'payee_details',
      formatter: 'coReceiptPayeeAddressFormatter',
    },
    {
      header: 'Download',
      accessorKey: '_id',
      formatter: 'invoiceAndReceiptDownloadFormatter'
    }
  ],
}

export const ORG_EXTRA_USER_INFO = {
  clearout: [
    {
      label: 'Id',
      accessorKey: '_id',
      formatter: 'userIdFormatter',
    },
    {
      label: 'Remaining Credits',
      accessorKey: 'credits.total',
      formatter: 'orgRemainingCreditsFormatter',
    },
    {
      label: 'Add More Seat(s)',
      // accessorKey: 'credits.total',
      formatter: 'addMoreSeatsFormatter',
    },

  ],
  clearoutphone: [
  ],
  spokesly_clearout: [
    {
      label: 'Id',
      accessorKey: '_id',
      formatter: 'userIdFormatter',
    },
    {
      label: 'Remaining Credits',
      accessorKey: 'credits.total',
      formatter: 'orgRemainingCreditsFormatter',
    },
    {
      label: 'Add More Seat(s)',
      // accessorKey: 'credits.total',
      formatter: 'addMoreSeatsFormatter',
    },
  ],
  eu_clearout: [
    {
      label: 'Id',
      accessorKey: '_id',
      formatter: 'userIdFormatter',
    },
    {
      label: 'Remaining Credits',
      accessorKey: 'credits.total',
      formatter: 'orgRemainingCreditsFormatter',
    },
    {
      label: 'Add More Seat(s)',
      // accessorKey: 'credits.total',
      formatter: 'addMoreSeatsFormatter',
    },
  ],
  ep_clearout: [
    {
      label: 'Id',
      accessorKey: '_id',
      formatter: 'userIdFormatter',
    },
    {
      label: 'Remaining Credits',
      accessorKey: 'credits.total',
      formatter: 'orgRemainingCreditsFormatter',
    },
    {
      label: 'Add More Seat(s)',
      // accessorKey: 'credits.total',
      formatter: 'addMoreSeatsFormatter',
    },
  ],
  cams_clearout: [
    {
      label: 'Id',
      accessorKey: '_id',
      formatter: 'userIdFormatter',
    },
    {
      label: 'Remaining Credits',
      accessorKey: 'credits.total',
      formatter: 'orgRemainingCreditsFormatter',
    },
    {
      label: 'Add More Seat(s)',
      // accessorKey: 'credits.total',
      formatter: 'addMoreSeatsFormatter',
    },
  ],
  pepipost_clearout: [
    {
      label: 'Id',
      accessorKey: '_id',
      formatter: 'userIdFormatter',
    },
    {
      label: 'Remaining Credits',
      accessorKey: 'credits.total',
      formatter: 'orgRemainingCreditsFormatter',
    },
    {
      label: 'Add More Seat(s)',
      // accessorKey: 'credits.total',
      formatter: 'addMoreSeatsFormatter',
    },
  ],

}

export const CO_INVOICE_DOWNLOAD_ENDPOINT = (client) => `api/v1/${client}/download/invoice?`
export const CO_RECEIPT_DOWNLOAD_ENDPOINT = (client) => `api/v1/${client}/download/receipt?`

export const COP_INVOICE_DOWNLOAD_ENDPOINT = 'v1/crm/download/invoice?'
export const COP_RECEIPT_DOWNLOAD_ENDPOINT = 'v1/crm/download/receipt?'