import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';

import * as Constants from '../../helpers/constants'
import showToastNotification from '../widgets/toastify';
import { ApiHelper } from '../../helpers/apihelper';

const initialState = {
  rateLimit: '',
  reason: '',
  errors: {},
  showModal: false,
  isApiCallInProgress: false
}

function AllowedDailyLimitModal({ data, getUserInfo, client }) {
  const [state, setState] = useState(initialState)
  const id = useSelector(state => state.user.id);
  const userName = useSelector(state => state.user.name);

  const validateForm = () => {
    let err = {}
    if (state.rateLimit.toString().length > 10) {
      err['limits'] = 'Please enter limit less than 10 chars'
    }
    if (state.rateLimit <= 0) {
      err['limits'] = 'limit must be positive number';
    }
    if (Object.keys(err).length) {
      setState((prevState) => ({ ...prevState, errors: err }))
      setTimeout(() => {
        setState((prevState) => ({ ...prevState, errors: {} }))
      }, 5000);
      return false
    }
    return true
  }

  const handleSaveChanges = () => {
    if (validateForm()) {
      let requestBody = {
        co_org_id: data._id,
        cop_user_id: data.user_id,
        user_id: id,
        crm_user_id: id,
        allowed_daily_limit: state.rateLimit,
        reason: state.reason,
        crm_user_data: {
          name: userName
        },
        client_secret: Constants.CLIENT_SECRET,
      }

      let body = _.pick(requestBody, ApiHelper(client, 'ORG_ADD_DAILY_LIMIT').API_BODY)

      setState(prevState => ({ ...prevState, isApiCallInProgress: true }));
      ApiHelper(client, 'ORG_ADD_DAILY_LIMIT').API(client, body)
        .then((response) => {
          if (response.status === Constants.CO_API.FAILED) {
            showToastNotification({
              message: 'Daily Limit Set unsuccessful',
              isSuccess: false,
            });
          } else {
            (response.status === Constants.CO_API.SUCCESS)
            getUserInfo()
            setState((prevState) => ({
              ...prevState,
              showModal: false,
            }));
            showToastNotification({
              message: 'Daily Limit Set successful',
              isSuccess: true,
            });
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          setState(prevState => ({ ...prevState, isApiCallInProgress: false }));
        })
    }
  }

  const handleShowModal = () => {
    setState(prevState => ({ ...prevState, showModal: true }));
  };

  const handleCloseModal = () => {
    setState(initialState)
  }


  return (
    <>
      <Button
        onClick={handleShowModal}
        variant='outline-primary'
        size="sm"
      >
        <FontAwesomeIcon icon="fa fa-pencil" />
      </Button>
      <Modal
        show={state.showModal}
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title className='fs-6 fw-bold '>

            Set Daily Limit
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-bg-color'>
          <Form>
            <div className='row'>
              <Form.Group
                className='d-flex'
              >
                <div className='col-md-3'>
                  <Form.Label className='w-100 py-2'>Enter Limit:</Form.Label>
                </div>
                <div className='col-md-9'>
                  <Form.Control
                    type="number"
                    placeholder="Enter Limit"
                    value={state.rateLimit}
                    onChange={(e) => setState(prevState => ({ ...prevState, rateLimit: e.target.value }))}
                  />
                  {state.errors ? <p className='text-danger'>{state.errors.limits}</p> : null}
                </div>
              </Form.Group>
            </div>
            <div className='row'>
              <Form.Group
                className='d-flex'
              >
                <div className='col-md-3'>
                  <Form.Label className='w-100 py-2'>Description:</Form.Label>
                </div>
                <div className='col-md-9'>
                  <Form.Control
                    type="text"
                    placeholder="Enter Description"
                    value={state.reason}
                    onChange={(e) => setState(prevState => ({ ...prevState, reason: e.target.value }))}
                  />
                </div>
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={handleSaveChanges}
            disabled={!state.rateLimit || !state.reason || state.isApiCallInProgress}
          >
            Set
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AllowedDailyLimitModal;
