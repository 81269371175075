import React, { useEffect, useState } from 'react';
import { Form, Table } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { usePagination } from '@table-library/react-table-library/pagination';

import showToastNotification from '../widgets/toastify';
import UpdateAbuseModal from './update_abuse_entry';
import * as Constants from '../../helpers/constants';
import { ApiHelper } from '../../helpers/apihelper';

import 'react-toggle/style.css';
import '../../static/css/abuse.css';

const initialState = {
  pageSize: 50,
  showModal: false,
  currentValue: {},
  currentPageIndex: 0,
};

const AbuseListTable = ({ getAbuseList, data, client }) => {
  const [state, setState] = useState(initialState);
  const id = useSelector((state) => state.user.id);
  const userName = useSelector((state) => state.user.name);

  useEffect(() => {
    getAbuseList(state.currentPageIndex * state.pageSize, state.pageSize, null, { created_on: 'desc' });
  }, [getAbuseList, state.pageSize, state.currentPageIndex]);

  const handleSaveChanges = () => {
    const requestBody = {
      ...state.currentValue,
      user_id: id,
      crm_user_data: {
        user_id: id,
        name: userName,
      },
      client_secret: Constants.CLIENT_SECRET,
    };

    let body = _.pick(requestBody, ApiHelper(client, 'UPDATE_ABUSE_ENTRY').API_BODY)

    ApiHelper(client, 'UPDATE_ABUSE_ENTRY').API(client, body)
      .then((response) => {
        if (response.status === Constants.CO_API.FAILED) {
          showToastNotification({
            message: 'Failed to update',
            isSuccess: false,
          });
        } else if (response.status === Constants.CO_API.SUCCESS) {
          showToastNotification({
            message: 'Successfully updated',
            isSuccess: true,
          });
          handleClose();
          getAbuseList(state.currentPageIndex * state.pageSize, state.pageSize, null, { created_on: 'desc' });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const columns = [
    { label: 'Sr No', renderCell: (item, index) => index + 1 },
    { label: 'Type', renderCell: (item) => item.type },
    {
      label: 'Value',
      renderCell: (item) => (
        <span
          onClick={() => handleCellClick(item)}
          style={{ cursor: 'pointer', color: 'blue' }}
        >
          {item.value}
        </span>
      ),
    },
    { label: 'Relaxed', renderCell: (item) => exemptedFormat(item.exempted) },
    { label: 'Block Free Email', renderCell: (item) => blockFreeEmailFormat(item.block_free_email, item) },
    { label: 'Reason', renderCell: (item) => item.reason },
    { label: 'Created On', renderCell: (item) => createdOnTimeFormat(item.created_on) },
    { label: 'Modified On', renderCell: (item) => createdOnTimeFormat(item.modified_on) },
  ];

  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setState((prevState) => ({ ...prevState, pageSize: newSize, currentPageIndex: 0 }));
    getAbuseList(0, newSize, null, { created_on: 'desc' });
  };

  const handleCellClick = (item) => {
    setState((prevState) => ({
      ...prevState,
      currentValue: item,
      showModal: true,
    }));
  };

  const handleClose = () => setState((prevState) => ({ ...prevState, showModal: false }));

  const onBlockFreeEmailChange = () => {
    setState((prevState) => ({
      ...prevState,
      currentValue: {
        ...prevState.currentValue,
        block_free_email: !prevState.currentValue.block_free_email,
      },
    }));
  };

  const onExemptedChange = () => {
    setState((prevState) => ({
      ...prevState,
      currentValue: {
        ...prevState.currentValue,
        exempted: !prevState.currentValue.exempted,
      },
    }));
  };

  const createdOnTimeFormat = (cell) => {
    const date = moment(cell).format('DD-MMM-YYYY @ h:mm:ss a');
    return <span title={date}>{date}</span>;
  };

  const exemptedFormat = (cell) => {
    const exempted = cell ? 'Yes' : 'No';
    return <span title={exempted}>{exempted}</span>;
  };

  const blockFreeEmailFormat = (cell, data) => {
    const blockFreeEmail = cell ? 'Yes' : 'No';
    return <span>{data.type === 'country' ? <span title={blockFreeEmail}>{blockFreeEmail}</span> : null}</span>;
  };

  const onPaginationChange = (action, newState) => {
    getAbuseList(newState.page * LIMIT, LIMIT, null, { created_on: 'desc' });
    setState((prevState) => ({
      ...prevState,
      currentPageIndex: newState.page,
    }));
  };

  const LIMIT = state.pageSize;
  const pagination = usePagination(
    data,
    {
      state: {
        page: state.currentPageIndex,
        size: LIMIT,
      },
      onChange: onPaginationChange,
    }
  );

  const handleReason = (event) => {
    setState((prevState) => ({
      ...prevState,
      currentValue: {
        ...prevState.currentValue,
        reason: event.target.value,
      },
    }));
  };

  const renderHeader = () => (
    <thead className="sticky-header">
      <tr>
        {columns.map((col, index) => (
          <th key={index}>{col.label}</th>
        ))}
      </tr>
    </thead>
  );

  const renderRows = () => {
    if (!data || !data.nodes) return null;

    return (
      <tbody>
        {data.nodes.map((item, index) => (
          <tr key={index}>
            {columns.map((col, colIndex) => (
              <td key={colIndex}>{col.renderCell(item, index)}</td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  };

  return (
    <>
      <UpdateAbuseModal
        showModal={state.showModal}
        handleClose={handleClose}
        handleSaveChanges={handleSaveChanges}
        handleReason={handleReason}
        currentValue={state.currentValue}
        onBlockFreeEmailChange={onBlockFreeEmailChange}
        onExemptedChange={onExemptedChange}
      />
      <div className="table-container">
        <Table
          striped
          bordered
          hover
        >
          {renderHeader()}
          {renderRows()}
        </Table>
      </div>
      <div className="d-flex justify-content-between pt-3">
        <Form.Select
          value={state.pageSize}
          onChange={handlePageSizeChange}
          className='vertical-pagination'
        >
          {Constants.PAGE_SIZE.values.map((size) => (
            <option
              key={size}
              value={size}
            >
              {size}
            </option>
          ))}
        </Form.Select>
        <div>
          <button
            type="button"
            disabled={pagination.state.page === 0}
            onClick={() => pagination.fns.onSetPage(0)}
          >
            {'|<'}
          </button>
          <button
            type="button"
            disabled={pagination.state.page === 0}
            onClick={() => pagination.fns.onSetPage(pagination.state.page - 1)}
          >
            {'<'}
          </button>
          <button
            type="button"
            onClick={() => pagination.fns.onSetPage(pagination.state.page + 1)}
          >
            {'>'}
          </button>
        </div>
      </div>
    </>
  );
};

export default AbuseListTable;
