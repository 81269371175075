import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const showToastNotification = ({message, isSuccess}) => {   //here we can add more params if required
  toastWrapper({ message, isSuccess });
};

const toastWrapper = ({ message, isSuccess, position, autoClose, hideProgressBar, closeOnClick, pauseOnHover, draggable, progress }) => {
  toast[isSuccess ? 'success' : 'error'](message, {
    position: position || 'top-right',
    autoClose: typeof autoClose === 'number' ? autoClose : 3000,
    hideProgressBar: hideProgressBar || false,
    closeOnClick: closeOnClick || true,
    pauseOnHover: pauseOnHover || false,
    draggable: draggable || false,
    progress: progress || undefined,
  });
};

export default showToastNotification;
