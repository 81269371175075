import React from 'react'

import AttatchProduct from './attach_product'
import DetachProduct from './detach_product'

const PlanTab = ({ userData, client }) => {

  const userHasCustomProducts = userData.tags.includes('custom plans')

  return (
    <div className='plan-tab'>
      <div className='plan-tab-headers'>
        <AttatchProduct
          userData={userData}
          client={client}
        />
        {
          userHasCustomProducts
            ? <DetachProduct
              userData={userData}
              client={client}
            />
            : null
        }
      </div>
    </div>
  )
}

export default PlanTab
