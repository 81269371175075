import React, { useState, useCallback, useRef, useEffect ,useContext} from 'react';
import { Container } from 'react-bootstrap';
import _ from 'lodash';

import * as Constants from '../../helpers/constants';
import AbuseListTable from './abuse_list_table';
import FiltersComponent from '../widgets/filters';
import * as FiltersConstants from '../widgets/filters/constants';
import { ApiHelper } from '../../helpers/apihelper';
import AppConfigContext from '../dashboard/app_config_context';

import '../../static/css/userlist.css';

const initialState = {
  abuse_list: { nodes: [] },
  tableDataLength: 1,
  searchText: '',
  sort: {
    'updatedAt': 'desc'
  },
  filters: {
    date_range: {
      filter_label: 'date_range',
      value: {
        key: 'created_on',
        preset: 'ps_last_7_days_including_today'
      }
    },
    task: {
      filter_label: 'Action',
      label: 'All',
      value: null
    }
  },
};

const filterParams = (filters) => {
  let queryFilter = {
    task: filters.task?.value || null,
    name: filters.name?.value || '',
    date_range: filters.date_range?.value || null,
  };
  if (queryFilter.status === 'all') {
    queryFilter.status = null;
  }
  return queryFilter;
};

const AbuseLogs = () => {
  const [state, setState] = useState(initialState);
  const abortControllerRef = useRef(null); // Reference to store the AbortController

  const appConfig = useContext(AppConfigContext)
  const client = appConfig.client

  const getAbuseList = useCallback((skip, limit, searchText) => {
    // Abort any ongoing request before making a new one
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController for the new request
    const controller = new AbortController();
    abortControllerRef.current = controller;

    let requestBody = {
      skip: skip,
      limit: limit,
      search: searchText,
      sort: state.sort,
      filters: { ...filterParams(state.filters) },
      client_secret: Constants.CLIENT_SECRET,
    };

    let body = _.pick(requestBody, ApiHelper(client, 'GET_ABUSE_LOGS').API_BODY)
    ApiHelper(client, 'GET_ABUSE_LOGS').API(client, body, abortControllerRef.current.signal)
      .then((res) => {
        if (res.status === Constants.CO_API.SUCCESS) {
          const updatedData = res.data.data.map(v => {
            v.id = v.id || _.uniqueId('abuse_');
            return v;
          });

          setState(prevState => ({
            ...prevState,
            abuse_list: { nodes: updatedData },
            tableDataLength: updatedData.length
          }));
        } else if (res.status === Constants.CO_API.FAILED) {
          console.log('Failed to get abuse logs');
        }
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          console.log(err);
        }
      });
  }, [state.filters, state.sort, client]);

  // Handle component unmounting to abort ongoing requests
  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const onSearch = (event) => {
    const newSearchText = event.target.value;
    setState(prevState => ({ ...prevState, searchText: newSearchText }));
    getAbuseList(0, 50, newSearchText);
  };

  const clearSearch = () => {
    setState(prevState => ({ ...prevState, searchText: '' }));
    getAbuseList(0, 50, '', { created_on: 'desc' });
  };

  const onSetFilter = (filters) => {
    setState(prevState => ({ ...prevState, filters: { ...filters } }));
  };

  const clientDisplayName = Constants.CLIENTS[client.toUpperCase()].DISPLAY_NAME;

  return (
    <Container fluid>
      <h1 className='header'>{clientDisplayName} Abuse Logs</h1>
      <FiltersComponent
        filterState={state.filters}
        onSearch={onSearch}
        clearSearch={clearSearch}
        searchValue={state.searchText}
        onSetFilters={onSetFilter}
        filters={FiltersConstants.ABUSE_LOGS[client].filters}
        filtersToInclude={FiltersConstants.FILTERS_TO_INCLUDE[client].ABUSE_LOGS}
        resetFilters={FiltersConstants.ABUSE_LOGS[client].onResetFilters}
        skipFilter={['date_range', 'org_filter']}
        client={client}
      />
      <div className='pt-3'>
        <AbuseListTable
          getAbuseList={getAbuseList}
          data={state.abuse_list}
          client={client}
          setState={setState}
        />
      </div>
    </Container>
  );
};

export default AbuseLogs;
