import React, { useState, useEffect } from 'react';

import * as Constants from '../../helpers/constants';

import { CircularProgressbar } from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';

const CircularProgress = ({ listDetails }) => {
  const [details, setDetails] = useState(listDetails);

  useEffect(() => {
    setDetails(listDetails);
  }, [listDetails]);

  const showVerifyOrCancel = () => {
    if (details.status === Constants.LIST_STATUSES.CANCELLED) {
      return (
        <div className="progress-info-div">
          <p className="w-auto mt-2 mx-auto">
            <span>Cancelling...</span>
          </p>
        </div>
      );
    } else {
      const actionStatus =
        Constants.LIST_ACTIONS[details.action_inprogress.action].progress;
      return (
        <p className="w-auto mt-2 mx-auto">
          <span>{actionStatus}</span>
        </p>
      );
    }
  };

  const progressVal = details.progress ? details.progress.progress : 0;
  const text = progressVal !== null ? `${progressVal.toFixed(2)}%` : '';

  return (
    <div className="progress-info-div">
      <div className="file-upload-progress">
        <CircularProgressbar
          value={progressVal !== null ? progressVal.toFixed(2) : 0}  // changed from `percentage` to `value`
          text={text}
          strokeWidth={5}
          initialAnimation={true}
          styles={{
            path: {
              stroke: '#63b306',
            },
            text: {
              fill: '#63b306',
            },
          }}
        />
        {showVerifyOrCancel()}
      </div>
    </div>
  );
};

export default CircularProgress;
