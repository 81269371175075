import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import * as Constants from '../../helpers/constants';
import * as Helper from '../../helpers/helper';

const DeliverabilityScoreTable = (props) => {
  const [state, setState] = useState({
    metrics: props.metrics,
    resultsArray: props.resultsArray,
    lastVerifiedOn: props.lastVerifiedOn,
    individualList: props.individualList,
  });

  useEffect(() => {
    setState({
      metrics: props.metrics,
      resultsArray: props.resultsArray,
      lastVerifiedOn: props.lastVerifiedOn,
      individualList: props.individualList,
    });
  }, [props.metrics, props.resultsArray, props.lastVerifiedOn, props.individualList]);

  const getDeliverabilityScore = (deliverability_score) => {
    const score = state.lastVerifiedOn ? Helper.getDeliveryStatusFromDeliverableScore(deliverability_score) : '-';
    return state.lastVerifiedOn ? (
      <td className='deliverability-value'>
        {deliverability_score}%
        <span className={`delivery_status ${score.value}`}>
          ({score.name})
        </span>
      </td>
    ) : (
      <td className='deliverability-value'>-</td>
    );
  };

  let confidence_level_text = '';
  const deliverabilityTableDisplayOrder = props.emailFinder
    ? Constants.EMAIL_FINDER_DELIVERABILITY_TABLE_DISPLAY_ORDER
    : Constants.DELIVERABILITY_TABLE_DISPLAY_ORDER;

  return (
    <table className='deliverability-results-info-table table table-borderless table-sm'>
      <tbody>
        {state.resultsArray.map((result, index) => {
          if (deliverabilityTableDisplayOrder.includes(result)) {
            if (result === Constants.EMAIL_FINDER_DELIVERABILITY_TABLE_DISPLAY_ORDER[2]) {
              confidence_level_text = state.metrics[result] || '-';
              confidence_level_text = confidence_level_text.replace(/\s/g, '-');
            }
            return (
              <tr key={index}>
                <td>{Constants.RESULTS[result].display_name}</td>
                {result === 'deliverability_score' ? (
                  getDeliverabilityScore(state.metrics[result])
                ) : (
                  <td className={classnames('deliverability-value', confidence_level_text ? `${confidence_level_text}` : '')}>
                    {Helper.getResultAsReqFormat(result, state.metrics[result])}
                  </td>
                )}
              </tr>
            );
          }
          return null;
        })}
        {state.individualList.verification_result && state.individualList.verification_result.safe_to_send ? (
          <tr>
            <td>
              <div className='result-name-data'>
                Guaranteed Deliverables
              </div>
            </td>
            <td>
              <div className='deliverability-value delivery_status no_risk'>
                {state.individualList.verification_result.safe_to_send['yes'].value}
              </div>
            </td>
          </tr>
        ) : null}
      </tbody>
    </table>
  );
};

export default DeliverabilityScoreTable;
