import React from 'react'
import { Form } from 'react-bootstrap'

const CustomCheckOrRadio = ({
  type = 'checkbox',
  name = 'custom_checkbox',
  checked,
  onValueChange,
  disabled
}) => {

  const onInputChange = (e) => {
    onValueChange(e)
  }

  return (
    <div className='product-custom-check'>
      <Form.Check
        id='custom-product'
        name={name}
        type={type}
        checked={checked}
        onChange={onInputChange}
        disabled={disabled}
      />
    </div>
  )
}

export default CustomCheckOrRadio