import React from 'react'
import _ from 'lodash'
import { Badge } from 'react-bootstrap'

const FilterTagComponent = ({
  selectedFilters,
  onRemoveSelectedFilter,
  skipFilter = [],
}) => {

  return (
    _.map(selectedFilters, (filter, key) => {
      if (!_.isEmpty(filter) && !skipFilter.includes(key)) {
        return (
          <Badge
            key={key}
            color="default"
            className="filter-badge"
          >
            {selectedFilters[key].filter_label ? selectedFilters[key].filter_label + ': ' : null}
            <span>{filter.label}</span>
            <span
              className="close-icon"
              onClick={() => onRemoveSelectedFilter(key, filter)}
            />
          </Badge>
        )
      }

    })
  )
}

export default FilterTagComponent
