import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import * as Constants from '../../helpers/constants'
import showToastNotification from '../widgets/toastify';
import { ApiHelper } from '../../helpers/apihelper';

const initialState = {
  showModal: false,
  emailAddress: '',
  errors: {},
  isApiCallInProgress: false
}

function UpdateEmailModal({ couserid, getUserInfo, data, client }) {
  const [state, setState] = useState({
    ...initialState,
    emailAddress: data.email
  });
  const id = useSelector(state => state.user.id);

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setState(prevState => ({ ...prevState, emailAddress: emailValue }));
  };

  const validateEmail = (email) => {
    const emailRegex = Constants.REGEX.EMAIL_SYNTAX;
    const isValidEmail = emailRegex.test(email);

    if (!isValidEmail) {
      setState(prevState => ({
        ...prevState, errors: {
          emailAddress: 'Enter a valid email address',
        },
      }));

      setTimeout(() => {
        setState(prevState => ({
          ...prevState,
          errors: {
            emailAddress: null,
          },
        }));
      }, 5000);
    }

    return isValidEmail;
  };

  const handleSaveChanges = () => {
    const isValidEmail = validateEmail(state.emailAddress);

    if (!isValidEmail) {
      return;
    }
    let requestBody = {
      co_user_id: couserid,
      cop_user_id: couserid,
      user_id: id,
      email: state.emailAddress,
      client_secret: Constants.CLIENT_SECRET,
    };
    let body = _.pick(requestBody, ApiHelper(client, 'EDIT_EMAIL').API_BODY)

    setState(prevState => ({ ...prevState, isApiCallInProgress: true }));
    ApiHelper(client, 'EDIT_EMAIL').API(client, body)
      .then((response) => {
        if (response.status === Constants.CO_API.FAILED) {
          showToastNotification({
            message: response.message,
            isSuccess: false,
          });
        } else if (response.status === Constants.CO_API.SUCCESS) {
          showToastNotification({
            message: response.message,
            isSuccess: true,
          });
          getUserInfo();
          setState((prevState) => ({
            ...prevState,
            showModal: false,
          }));
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setState(prevState => ({ ...prevState, isApiCallInProgress: false }));
      })
  }

  const handleShowModal = () => {
    setState(prevState => ({ ...prevState, showModal: true }));
  };

  const handleCloseModal = () => {
    setState(prevState => ({ ...prevState, showModal: false, emailAddress: data.email }))
  }

  return (
    <>
      <Button
        onClick={handleShowModal}
        variant='outline-primary'
        size="sm"
        className='sm-btns'
      >
        <FontAwesomeIcon icon="fa fa-pencil" />
      </Button>
      <Modal
        show={state.showModal}
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title className='fs-6 fw-bold'>Update Email</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-bg-color'>
          <Form>
            <div className='row'>
              <Form.Group
                className='d-flex'
              >
                <div className='col-md-3'>
                  <Form.Label className='w-100 py-2'>Change Email:</Form.Label>
                </div>
                <div className='col-md-9'>
                  <Form.Control
                    type="email"
                    placeholder="Enter Email Address"
                    value={state.emailAddress}
                    onChange={handleEmailChange}
                    isInvalid={!!state.errors.emailAddress}
                  />
                  <Form.Control.Feedback type="invalid">
                    {state.errors.emailAddress}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={handleSaveChanges}
            disabled={state.isApiCallInProgress}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdateEmailModal;
