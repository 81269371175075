import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

const TablePagination = ({ currentPageIndex, totalpage, updateTableData }) => {
  const handlePrevClick = () => {
    if (currentPageIndex > 0) {
      updateTableData(currentPageIndex - 1);
    }
  };

  const handlePageItemClick = (pageIndex) => {
    updateTableData(pageIndex);
  };

  const handleNextClick = () => {
    if (currentPageIndex < totalpage - 1) {
      updateTableData(currentPageIndex + 1);
    }
  };

  // Always render pagination component even when totalpage is 1
  const pageItems = [];
  const range = 2; // Number of pages to show on each side of the current page

  for (let i = Math.max(0, currentPageIndex - range); i <= Math.min(totalpage - 1, currentPageIndex + range); i++) {
    pageItems.push(
      <Pagination.Item
        key={i}
        active={i === currentPageIndex}
        onClick={() => handlePageItemClick(i)}
      >
        {i + 1}
      </Pagination.Item>
    );
  }

  return (
    <Pagination>
      <Pagination.First
        onClick={() => handlePageItemClick(0)}
        disabled={currentPageIndex === 0}
      />
      <Pagination.Prev
        onClick={handlePrevClick}
        disabled={currentPageIndex === 0}
      />
      {currentPageIndex > range && <Pagination.Ellipsis disabled />}
      {pageItems}
      {currentPageIndex < totalpage - range - 1 && <Pagination.Ellipsis disabled />}
      <Pagination.Next
        onClick={handleNextClick}
        disabled={currentPageIndex + 1 === totalpage}
      />
      <Pagination.Last
        onClick={() => handlePageItemClick(totalpage - 1)}
        disabled={currentPageIndex + 1 === totalpage}
      />
    </Pagination>
  );
};

export default TablePagination;
