import React, { Suspense } from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom'

// import App from './app'
import dashboardRoutes from './component/dashboard'
import nonAuthenticatedRoutes from './non_authenticated_router';
import Login from './component/login/login'
import { UserIsAuthenticated } from './authenticators'
import RouteLoader from './helpers/routeLoader'

const rootRouter = createBrowserRouter([
  {
    id: 'root-login',
    path: '/login',
    element:
      <UserIsAuthenticated redirectPath='/dashboard'>
        <Suspense fallback={<RouteLoader />}>
          <Login />
        </Suspense>
      </UserIsAuthenticated>
  },
  ...nonAuthenticatedRoutes.routes,
  ...dashboardRoutes.routes,
  {
    id: 'root-redirect',
    path: '*',
    element:
      <Navigate
        to='/dashboard'
        replace={true}
      />
  }
])

export default rootRouter
