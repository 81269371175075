import React from 'react';
import { HashLoader } from 'react-spinners'

const RouteLoader = ({ size = 100, color = '#712cf9', loading = true, speedMultiplier = 1 }) => {

  return (
    <HashLoader
      className='m-auto h-100'
      size={size}
      color={color}
      loading={loading}
      speedMultiplier={speedMultiplier}
    />
  )
}

export default RouteLoader