import React, { Suspense, lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom'

import RouteLoader from '../src/helpers/routeLoader';

const Download = lazy(() => import('../src/component/download/index'))
const DownloadWithoutPolling = lazy(() => import('../src/component/download/download_without_polling'))

const nonAuthenticatedRouter = createBrowserRouter([
  {
    id: 'download-with-polling',
    path: 'download_result/:download_id',
    element:
          <Suspense fallback={<RouteLoader />}>
            <Download />
          </Suspense>
  },
  {
    id: 'download-without-polling',
    path: 'download/result',
    element:
          <Suspense fallback={<RouteLoader />}>
            <DownloadWithoutPolling />
          </Suspense>
  },
])

export default nonAuthenticatedRouter