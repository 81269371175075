import clearoutApiHelper from './clearout_api_helper'
import clearoutPhoneApiHelper from './clearoutphone_api_helper'

import { CLIENTS } from '../constants'

export const ApiHelper = (client, apiName) => {
  let clientService = CLIENTS[client.toUpperCase()].SERVICE

  switch (clientService) {
    case CLIENTS.CLEAROUT.SERVICE: {
      return clearoutApiHelper[apiName.toUpperCase()]
    }
    case CLIENTS.CLEAROUTPHONE.SERVICE: {
      return clearoutPhoneApiHelper[apiName.toUpperCase()]
    }
  }
}