import React from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import Toggle from 'react-toggle';

import 'react-toggle/style.css';

const labels = {
  country: 'Country:',
  ip: 'IP:',
  domain: 'Domain:',
};

const UpdateAbuseModal = ({
  showModal,
  handleClose,
  handleSaveChanges,
  currentValue,
  handleReason,
  onBlockFreeEmailChange,
  onExemptedChange,
}) => {
  return (
    <Modal
      show={showModal}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title className='fs-6 fw-bold'>Update Abuse Entry</Modal.Title>
      </Modal.Header>
      <Modal.Body className='modal-body-bg-color'>
        <Form>
          <div className='row'>
            <Form.Group className='d-flex'>
              <div className='col-md-3'>
                <Form.Label className='w-100 py-2'>Abuse Type</Form.Label>
              </div>
              <div className='col-md-9'>
                <Form.Control
                  type='text'
                  value={currentValue.type}
                  disabled
                />
              </div>
            </Form.Group>
          </div>
          <div className='row'>
            <Form.Group className='d-flex'>
              <div className='col-md-3'>
                <Form.Label className='w-100 py-2'>{labels[currentValue.type]}</Form.Label>
              </div>
              <div className='col-md-9'>
                <Form.Control
                  type='text'
                  value={currentValue.value}
                  maxLength={50}
                  disabled
                />
              </div>
            </Form.Group>
          </div>
          {currentValue.type === 'country' && (
            <div className='row'>
              <Form.Group className='d-flex'>
                <div className='col-md-3'>
                  <Form.Label className='w-100 py-2'>Block Free Email:</Form.Label>
                </div>
                <div className='col-md-9'>
                  <label className='pt-1'>
                    <Toggle
                      checked={currentValue.block_free_email}
                      onChange={onBlockFreeEmailChange}
                    />
                  </label>
                </div>
              </Form.Group>
            </div>
          )}
          <div className='row'>
            <Form.Group className='d-flex'>
              <div className='col-md-3'>
                <Form.Label className='w-100 py-2'>Reason:</Form.Label>
              </div>
              <div className='col-md-9'>
                <Form.Control
                  type='text'
                  value={currentValue.reason}
                  onChange={handleReason}
                />
              </div>
            </Form.Group>
          </div>
          <div className='row'>
            <Form.Group className='d-flex'>
              <div className='col-md-3'>
                <Form.Label className='w-100 py-2'>Relax:</Form.Label>
              </div>
              <div className='col-md-9'>
                <label className='pt-1'>
                  <Toggle
                    checked={currentValue.exempted}
                    onChange={onExemptedChange}
                  />
                </label>
              </div>
            </Form.Group>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='primary'
          onClick={handleSaveChanges}
        >
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateAbuseModal;
