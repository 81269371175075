import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash'

import * as Constants from '../../../../../../helpers/constants';
import LineChart from '../../../../../widgets/linechart';
import FiltersComponent from '../../../../../widgets/filters';
import * as FiltersConstants from '../../../../../widgets/filters/constants'
import { ApiHelper } from '../../../../../../helpers/apihelper';

import '../analytics.css'
import 'react-circular-progressbar/dist/styles.css';

const EmailVerifier = ({ client }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const co_user_id = queryParams.get('user_id') || queryParams.get('org_id');

  const initialstate = {
    filters: {
      date_range: {
        filter_label: 'Date',
        label: 'Last 7 days (Including Today)',
        value: 'ps_last_7_days_including_today'
      }
    },
  }

  const [emailData, setEmailData] = useState({
    totalVerified: 0,
    bounceDetected: 0,
    estimatedCostSaved: 0,
  });
  const [lineChartData, setLineChartData] = useState({
    labels: [],
    breakdown: [],
    values: [],
  });

  const [state, setState] = useState(initialstate)
  useEffect(() => {
    const filter = {
      org_id: null,
      date_range: state.filters.date_range?.value ? state.filters.date_range?.value : null,
      member_id: null,
      user_id: co_user_id,
    };
    const requestQueryParams = {
      user_id: co_user_id,
      filters: JSON.stringify(filter),
    };

    let queryParams = _.pick(requestQueryParams, ApiHelper(client, 'EMAIL_VERIFY_REPORTS').API_QUERY)

    ApiHelper(client, 'EMAIL_VERIFY_REPORTS').API(client, queryParams)
      .then((response) => {
        if (response && response.status === Constants.CO_API.SUCCESS) {
          const stats = response.data.user_stats[0].stats;

          const cost_saved = stats.reduce((total, stat) => total + stat.cost_saved, 0)
          // Update state with the total
          setEmailData({
            totalVerified: stats.reduce((total, stat) => total + stat.total, 0),
            bounceDetected: stats.reduce((total, stat) => total + stat.bounce_detected, 0),
            estimatedCostSaved: Math.round(cost_saved),
          });

          // Update LineChart data
          setLineChartData({
            labels: stats.map((stat) => stat.date),
            breakdown: response.data.breakdown,
            values: [
              {
                label: 'Valid',
                data: stats.map((stat) => stat.valid),
              },
              {
                label: 'Invalid',
                data: stats.map((stat) => stat.invalid),
              },
              {
                label: 'Catch All',
                data: stats.map((stat) => stat.catch_all),
              },
              {
                label: 'Total',
                data: stats.map((stat) => stat.total),
              },
            ],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [co_user_id, state.filters, client]);


  const onSetFilter = (filters) => {
    setState(prevState => ({ ...prevState, filters: { ...filters } }))
  }


  return (
    <>
      <FiltersComponent
        filterState={state.filters}
        onSetFilters={onSetFilter}
        filters={FiltersConstants.ANALYTICS[client].filters}
        filtersToInclude={FiltersConstants.FILTERS_TO_INCLUDE[client].ANALYTICS}
        className='analytics-filter'
        resetFilters={FiltersConstants.ANALYTICS[client].onResetFilters}
      />
      <div className="overview-container border rounded p-3">
        <div className="row">
          {/* Left Box */}
          <div className="col text-center">
            <div className='total-verified fw-bold fs-5'>
              <p>Total Verified</p>
              <p className='text-orange'>{emailData.totalVerified}</p>
            </div>
            <div className='total-verified fw-bold fs-5'>
              <p>Bounce Detected</p>
              <p className='text-orange'>{emailData.bounceDetected}%</p>
            </div>
            <div className='total-verified fw-bold fs-5'>
              <p>Estimated Cost Saved</p>
              <p className='text-orange'>${emailData.estimatedCostSaved}</p>
            </div>
          </div>
          {/* Separator */}
          <div className="col-1 border-start"></div>
          {/* Right Box */}
          <div className="col-8 text-center analytics-chart">
            <LineChart
              graphData={lineChartData}
              width={300}
              height={250}
              position="bottom"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailVerifier;
