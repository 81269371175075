import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash'

import * as Constants from '../../../../../../helpers/constants';
import LineChart from '../../../../../widgets/linechart';
import FiltersComponent from '../../../../../widgets/filters';
import * as FiltersConstants from '../../../../../widgets/filters/constants'
import { ApiHelper } from '../../../../../../helpers/apihelper';

import '../analytics.css';

const Overview = ({ client }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const co_user_id = queryParams.get('user_id') || queryParams.get('org_id');

  const initialstate = {
    filters: {
      date_range: {
        filter_label: 'Date',
        label: 'Last 7 days (Including Today)',
        value: 'ps_last_7_days_including_today'
      }
    },
    // isLoading: false,
  }

  const [overviewData, setOverviewData] = useState({
    totalCredits: 0,
    emailVerifier: 0,
    emailFinder: 0,
    prospect: 0,
  });
  const [lineChartData, setLineChartData] = useState({
    labels: [],
    breakdown: [],
    values: [],
  });

  const [state, setState] = useState(initialstate)

  useEffect(() => {
    const filter = {
      org_id: null,
      date_range: state.filters.date_range?.value ? state.filters.date_range?.value : null,
      member_id: null,
      user_id: co_user_id,
    };
    const requestQueryParams = {
      user_id: co_user_id,
      filters: JSON.stringify(filter),
    };

    let queryParams = _.pick(requestQueryParams, ApiHelper(client, 'OVER_ALL_USAGE_REPORTS').API_QUERY)

    // setState(prevState => ({ ...prevState, isLoading: true }))
    ApiHelper(client, 'OVER_ALL_USAGE_REPORTS').API(client, queryParams)
      .then((response) => {
        if (response && response.status === Constants.CO_API.SUCCESS) {
          const stats = response.data.user_stats[0].stats;

          // Calculate total credits for all dates
          const totalCredits = stats.reduce(
            (total, stat) =>
              total +
              stat.ev_billable +
              stat.ef_billable +
              stat.prospect_billable,
            0
          );

          // Update state with the total credits
          setOverviewData({
            totalCredits,
            emailVerifier: stats.reduce((total, stat) => total + stat.ev_billable, 0),
            emailFinder: stats.reduce((total, stat) => total + stat.ef_billable, 0),
            prospect: stats.reduce((total, stat) => total + stat.prospect_billable, 0),
          });
          setLineChartData({
            labels: stats.map((stat) => stat.date),
            breakdown: response.data.breakdown,
            values: [
              {
                label: 'Total Credits',
                data: stats.map((stat) => stat.total),
              },
              {
                label: 'Email Verifier',
                data: stats.map((stat) => stat.ev_billable),
              },
              {
                label: 'Email Finder',
                data: stats.map((stat) => stat.ef_billable),
              },
              {
                label: 'Prospect',
                data: stats.map((stat) => stat.prospect_billable),
              },
            ],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      })
    // .finally(() => {
    //   setState(prevState => ({ ...prevState, isLoading: false }))
    // })
  }, [co_user_id, state.filters, client]);

  const onSetFilter = (filters) => {
    setState(prevState => ({ ...prevState, filters: { ...filters } }))
  }
  // const loader = state.isLoading ? (
  //   <LoadingWrapper
  //     type="bars"
  //     color="#007bff"
  //     height={50}
  //     width={50}
  //   />
  // ) : null

  return (
    <>
      <FiltersComponent
        filterState={state.filters}
        onSetFilters={onSetFilter}
        filters={FiltersConstants.ANALYTICS[client].filters}
        filtersToInclude={FiltersConstants.FILTERS_TO_INCLUDE[client].ANALYTICS}
        className='analytics-filter'
        resetFilters={FiltersConstants.ANALYTICS[client].onResetFilters}
      />
      <div className="overview-container border rounded p-3">
        <div className="row">
          {/* Left Box */}
          <div className="col text-center">
            <div className="total-verified fw-bold fs-5">
              <p>Total Credits</p>
              <p className="text-orange">{overviewData.totalCredits}</p>
            </div>
            <div className="total-verified fw-bold fs-5">
              <p>Email Verifier</p>
              <p className="text-orange">{overviewData.emailVerifier}</p>
            </div>
            <div className="total-verified fw-bold fs-5">
              <p>Email Finder</p>
              <p className="text-orange">{overviewData.emailFinder}</p>
            </div>
            <div className="total-verified fw-bold fs-5">
              <p>Prospect</p>
              <p className="text-orange">{overviewData.prospect}</p>
            </div>
          </div>
          {/* Separator */}
          <div className="col-1 border-start"></div>
          {/* Right Box */}
          <div className="col-8 text-center analytics-chart">
            <LineChart
              graphData={lineChartData}
              width={300}
              height={250}
              position="bottom"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
