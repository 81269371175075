import React from 'react'
import AnalyticsSidebar from './components/sidebar'

const Analytics = ({ client, userId }) => {

  return (
    <div>
      <AnalyticsSidebar
        client={client}
        userId={userId}
      />
    </div>
  );
};

export default Analytics;
