import React, { useEffect, useState } from 'react'

import ProductDropdown from './../../../widgets/custom_dropdown'

const INTERVAL_UNITS = [
  { label: 'Year', value: 'year', data: { min: 1, max: 1 } },
  { label: 'Month', value: 'month', data: { min: 1, max: 12 } },
  { label: 'Week', value: 'week', data: { min: 1, max: 52 } },
  { label: 'Day', value: 'day', data: { min: 1, max: 365 } }
]

const GET_INTERVAL_COUNT = (min, max) => Array.from(
  { length: max - min + 1 },
  (_v, _idx) => ({ label: _idx + min, value: _idx + min })
)

const INTERVAL_TIMES = [
  { label: 'One', value: 1 },
  { label: 'Infinity', value: Number.POSITIVE_INFINITY }
]

const initialStates = {
  interval_unit: INTERVAL_UNITS.find(v => v.value === 'month'),
  interval_count: { label: 1, value: 1 },
  interval_times: INTERVAL_TIMES.find(v => v.value === Number.POSITIVE_INFINITY),
  interval_count_opts: GET_INTERVAL_COUNT(1, 12)
}

const ProdcutIntervalDropdowns = ({ onStateChange }) => {

  const [state, setState] = useState(initialStates)

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      interval_count: initialStates.interval_count,
      interval_count_opts: GET_INTERVAL_COUNT(state.interval_unit.data.min, state.interval_unit.data.max)
    }))

    onStateChange('interval_count', initialStates.interval_count.value)
  }, [state.interval_unit, onStateChange])

  const onIntervalUnitChange = (selectedOption) => {
    setState((prevState) => ({
      ...prevState,
      interval_unit: selectedOption
    }))

    onStateChange('interval_unit', selectedOption.value)
  }

  const onIntervalCountChange = (selectedOption) => {
    setState((prevState) => ({
      ...prevState,
      interval_count: selectedOption
    }))

    onStateChange('interval_count', selectedOption.value)
  }

  const onIntervalTimesChange = (selectedOption) => {
    setState((prevState) => ({
      ...prevState,
      interval_times: selectedOption
    }))

    onStateChange('interval_times', selectedOption.value)
  }

  return (
    <div className="product-intervals">
      <div className="interval-unit">
        <label>Select Interval Unit</label>
        <ProductDropdown
          options={INTERVAL_UNITS}
          placeholder={'Select Interval Unit'}
          value={state.interval_unit}
          onSelectOption={onIntervalUnitChange}
          searchable={false}
        />
      </div>
      <div className="interval-count">
        <label>Select Interval Count</label>
        <ProductDropdown
          options={state.interval_count_opts}
          placeholder={'Select Interval Count'}
          value={state.interval_count}
          serchable={true}
          onSelectOption={onIntervalCountChange}
        />
      </div>
      <div className="interval-times">
        <label>Select Interval Unit</label>
        <ProductDropdown
          options={INTERVAL_TIMES}
          placeholder={'Select Interval Unit'}
          value={state.interval_times}
          onSelectOption={onIntervalTimesChange}
          searchable={false}
        />
      </div>
    </div>
  )
}

export default ProdcutIntervalDropdowns