import React, { useContext } from 'react'
import RemoveEmailFromCache from './remove_email_from_cache'
import UpdateDisposableDomain from './update_disposable_domain'
import RadarSettings from './radar/settings'
import AppConfigContext from '../dashboard/app_config_context'

import '../../static/css/miscellaneous.css'

const MiscellaneousApi = () => {

  const appConfig = useContext(AppConfigContext)
  const client = appConfig.client
  return (
    <div>
      <h1 className='fs-3'>Miscellaneous API</h1>
      <div className='d-flex gap-2'>
        <RemoveEmailFromCache
          client={client}
        />
        <UpdateDisposableDomain
          client={client}
        />
        <RadarSettings
          client={client}
        />
      </div>
    </div>
  )
}

export default MiscellaneousApi 
