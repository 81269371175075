import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';

import * as Constants from './../../helpers/constants'
import UserlistPage from '../user_list_page';
import ClearoutIndividualUserInfo from '../individual_user_info/clearout_individual_user_info';
import Coupons from '../coupons/coupons';
import ManageAbuse from '../abuse/manage_abuse';
import UpdateHistory from '../update_history/abuse_update_history';
import AbuseLogs from '../abuse_view_logs/abuse_view_logs';
import UserActivities from '../user_activities/user_activities';

const CLIENT = Constants.CLIENTS.CLEAROUTPHONE.NAME

const clearoutphoneRouter = createBrowserRouter([
  {
    id: 'dashboard-clearoutphone-route',
    path: CLIENT,
    children: [
      {
        id: 'dashboard-clearout-phone-users',
        path: 'users',
        element: <UserlistPage/>
      },
      {
        id: 'clearoutphone-individual-user-info',
        path: 'individual_user_info',
        element: <ClearoutIndividualUserInfo />
      },
      {
        id: 'copcoupons',
        path: 'coupons',
        element: <Coupons />
      },
      {
        id: 'copcoupon',
        path: 'coupons/:couponId',
        element: <Coupons />
      },
      {
        id: 'dashboard-clearoutphone-manage-abuse',
        path: 'abuse_list',
        element: <ManageAbuse />
      },
      {
        id: 'dashboard-clearoutphone-view-logs',
        path: 'abuse_logs',
        element: <AbuseLogs/>
      },
      {
        id: 'dashboard-clearoutphone-update-logs',
        path: 'abuse_update_logs',
        element: <UpdateHistory />
      },
      {
        id: 'dashboard-cop-user-activities',
        path: 'individual_user_activity',
        element: <UserActivities />
      },
      {
        id: CLIENT + '-redirect',
        path: '*',
        element:
          <Navigate
            to={'users'}
            replace={true}
          />
      },
    ]
  }
])

export default clearoutphoneRouter
