import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

const initialState = {
  showModal: false,
};

function CopViewStats({ userData }) {
  const [state, setState] = useState(initialState);

  const handleShowModal = () => {
    setState((prevState) => ({ ...prevState, showModal: true }));
  };

  const handleCloseModal = () => setState(initialState);

  return (
    <>
      <p
        onClick={handleShowModal}
        className='m-0 text-wrap'
      >
        view stats
      </p>
      <Modal
        show={state.showModal}
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-6 fw-bold">
            {userData.name || userData.email}&apos;s stats
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-bg-color">
          <div className='px-3'>
            <div>
              <div className="col d-flex justify-content-between">
                <p>Total:</p>
                <p>{userData.performance_stats.total}</p>
              </div>
            </div>
            <div>
              <div className="col d-flex justify-content-between">
                <p>Valid:</p>
                <p>{userData.performance_stats.valid}</p>
              </div>
            </div>
            <div>
              <div className="col d-flex justify-content-between">
                <p>Invalid:</p>
                <p>{userData.performance_stats.invalid}</p>
              </div>
              <div className="col d-flex justify-content-between">
                <p>Duplicate:</p>
                <p>{userData.performance_stats.duplicate}</p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CopViewStats;
