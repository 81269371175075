import React, { useState, useRef } from 'react'
import _ from 'lodash'
import Dropdown, { MenuItem } from '@trendmicro/react-dropdown'
import useOnClickOutside from '../../hooks/on_click_outside_div'

import '@trendmicro/react-buttons/dist/react-buttons.css'
import '@trendmicro/react-dropdown/dist/react-dropdown.css'

const FilterDropdownComponent = ({
  filters,
  onSelectFilter
}) => {
  const [isOpen, setIsOpen] = useState(false) // filter dropdown state
  const [openedSubMenu, setOpenedSubMenu] = useState('')

  const filterDivRef = useRef()

  useOnClickOutside(filterDivRef, () => setIsOpen(false))

  const onToggle = () => {
    setIsOpen(!isOpen)

    if (!isOpen) {
      setOpenedSubMenu('')
    }
  }

  return (
    <div
      onClick={onToggle}
      ref={filterDivRef}
    >
      <Dropdown
        open={isOpen}
        onToggle={() => { }}
        className="filter-class"
        style={{ display: 'flex' }}
      >
        <Dropdown.Toggle>
          <i className="fa fa-filter" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {_.map(filters, (filter, index) => (
            <MenuItem
              id={filter.value}
              className="filter-menu-item"
              key={index}
              open={openedSubMenu === filter.value}
            >
              {filter.name}
              {_.map(filter.menus, (menu, index) => {
                let event_key = {
                  label: menu.label,
                  value: menu.id ? { id: menu.id, role: menu.role } : menu.value,
                  filter_label: filter.name,
                  filter_value: filter.value
                }
                return (
                  <MenuItem
                    className="filter-menu-item"
                    key={index}
                    eventKey={event_key}
                    onSelect={onSelectFilter}
                  >
                    {menu.label}
                  </MenuItem>
                )
              })}
            </MenuItem>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default FilterDropdownComponent
