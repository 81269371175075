import React, { useState } from 'react';
import { Modal, Button, } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import * as Constants from '../../helpers/constants'
import showToastNotification from '../widgets/toastify'
import { ApiHelper } from '../../helpers/apihelper';

const initialState = {
  affiliate: true,
  isApiCallInProgress: false,
  showModal: false,
}

function RemoveDailyLimit({ data, getUserInfo, client }) {
  const [state, setState] = useState(initialState)
  const id = useSelector(state => state.user.id);
  const userName = useSelector(state => state.user.name);

  const handleSaveChanges = () => {
    let requestBody = {
      co_user_id: data.user_id,
      cop_user_id: data.user_id,
      affiliate: state.affiliate,
      user_id: id,
      crm_user_id: id,
      client_secret: Constants.CLIENT_SECRET,
      crm_user_data: {
        name: userName
      }
    };

    let body = _.pick(requestBody, ApiHelper(client, 'REMOVE_DAILY_LIMIT').API_BODY)

    setState(prevState => ({ ...prevState, isApiCallInProgress: true }));
    ApiHelper(client, 'REMOVE_DAILY_LIMIT').API(client, body)
      .then((response) => {
        if (response.status === Constants.CO_API.FAILED) {
          showToastNotification({
            message: 'Daily limit removed unsuccessfully',
            isSuccess: false,
          });
        } else {
          (response.status === Constants.CO_API.SUCCESS)
          getUserInfo()
          setState((prevState) => ({
            ...prevState,
            showModal: false,
          }));
          showToastNotification({
            message: 'Daily limit removed successfully',
            isSuccess: true,
          });
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setState(prevState => ({ ...prevState, isApiCallInProgress: false }));
      })
  };

  const handleShowModal = () => {
    setState(prevState => ({ ...prevState, showModal: true }));
  };

  const handleCloseModal = () => setState(initialState)


  return (
    <>
      <Button
        size="sm"
        variant='outline-danger'
        onClick={handleShowModal}
      >
        Remove Daily Limit
      </Button>
      <Modal
        show={state.showModal}
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title className='fs-6 fw-bold'>
            Remove Daily Limit
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={handleSaveChanges}
            disabled={state.isApiCallInProgress}
          >
            Yes
          </Button>
          <Button
            variant="danger"
            onClick={handleCloseModal}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RemoveDailyLimit;
