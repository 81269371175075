import React, { useState } from 'react'
import _ from 'lodash'
import Dropdown from 'react-bootstrap/Dropdown';

import * as IndividualUserConstants from '../helpers/constant'
import LogoutSessionModal from './components/logout_sessions'
import BlockUserModel from './components/block_user';
import UnBlockUserModel from './components/unblock_user';
import DeleteUserModel from './components/delete_user';
import SignUpLink from './components/signup_link';
import ForgotPasswordLink from './components/password_link';
import CopViewStats from './components/cop_view_stats';
import PaymentSettings from './components/payment_settings';
import PaymentAccount from './components/payment_accounts';

const ActionButtons = ({ client, userData, getUserInfo }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showBlockUserModal, setShowBlockUserModal] = useState(false);
  const [ShowUnBlockUserModal, setShowUnBlockUserModal] = useState(false)

  return (
    <>
      <Dropdown className='text-end'>
        <Dropdown.Toggle
          className='action-button'
          variant="primary"
        >
          Actions
        </Dropdown.Toggle>
        <Dropdown.Menu className='action-dropdown'>
          {_.includes(IndividualUserConstants.ACTION_BUTTONS[client], 'logout_sessions') &&
            userData.status !== 'inactive' ?
            <Dropdown.Item>
              <LogoutSessionModal
                userData={userData}
                getUserInfo={getUserInfo}
                client={client}
              />
            </Dropdown.Item>
            : null
          }
          {_.includes(IndividualUserConstants.ACTION_BUTTONS[client], 'block_user') &&
            userData.status === 'active' ?
            <>
              <Dropdown.Item
                as="button"
                onClick={() => setShowBlockUserModal(true)}
              >
                Block User
              </Dropdown.Item>
            </>
            : null
          }
          {_.includes(IndividualUserConstants.ACTION_BUTTONS[client], 'unblock_user') &&
            userData.status === 'inactive' && userData.account_status === 'live' ?
            <>
              <Dropdown.Item
                as="button"
                onClick={() => setShowUnBlockUserModal(true)}
              >
                Unblock User
              </Dropdown.Item>
            </>
            : null
          }
          {/* {_.includes(IndividualUserConstants.ACTION_BUTTONS[client], 'unblock_user') &&
            userData.status === 'inactive' && userData.account_status === 'live' ?
            <Dropdown.Item>
              <UnBlockUserModel
                userData={userData}
                getUserInfo={getUserInfo}
                client={client}
              />
            </Dropdown.Item>
            : null
          } */}
          {_.includes(IndividualUserConstants.ACTION_BUTTONS[client], 'forgot_password_link') &&
            userData.status !== 'inactive' ?
            <Dropdown.Item>
              <ForgotPasswordLink
                userData={userData}
                client={client}
              />
            </Dropdown.Item>
            : null
          }
          {_.includes(IndividualUserConstants.ACTION_BUTTONS[client], 'signup_link') &&
            userData && !userData.activated_on && userData.status !== 'inactive' ?
            <Dropdown.Item>
              <SignUpLink
                userData={userData}
                client={client}
              />
            </Dropdown.Item>
            : null
          }
          {_.includes(IndividualUserConstants.ACTION_BUTTONS[client], 'cop_view_stats') &&
            <Dropdown.Item>
              <CopViewStats
                userData={userData}
              />
            </Dropdown.Item>
          }
          {_.includes(IndividualUserConstants.ACTION_BUTTONS[client], 'payment_settings') &&
            userData.user_role === 'owner' && userData.status === 'active' ?
            <Dropdown.Item>
              <PaymentSettings
                userData={userData}
                getUserInfo={getUserInfo}
                client={client}
              />
            </Dropdown.Item>
            : null
          }
          {_.includes(IndividualUserConstants.ACTION_BUTTONS[client], 'default_payment_account') &&
            userData.user_role === 'owner' && userData.status === 'active' ?
            <Dropdown.Item>
              <PaymentAccount
                userData={userData}
                getUserInfo={getUserInfo}
                client={client}
              />
            </Dropdown.Item>
            : null
          }
          {_.includes(IndividualUserConstants.ACTION_BUTTONS[client], 'delete_user') &&
            userData.user_role === 'owner' && userData.status === 'active' &&
            <>
              <hr className='m-0' />
              <Dropdown.Item
                as="button"
                className='text-danger'
                onClick={() => setShowDeleteModal(true)}
              >
                Delete User
              </Dropdown.Item>
              <hr className='m-0' />
            </>
          }
        </Dropdown.Menu>
      </Dropdown>
      {/* Delete User Modal */}
      <DeleteUserModel
        show={showDeleteModal}  // Control the visibility of the modal
        onHide={() => setShowDeleteModal(false)}  // Function to close the modal
        userData={userData}
        getUserInfo={getUserInfo}
        client={client}
      />
      {/* block usermodal */}
      <BlockUserModel
        show={showBlockUserModal}  // Control the visibility of the modal
        onHide={() => setShowBlockUserModal(false)}  // Function to close the modal
        userData={userData}
        getUserInfo={getUserInfo}
        client={client}
      />
      {/* unblock user */}
      <UnBlockUserModel
        show={ShowUnBlockUserModal}  // Control the visibility of the modal
        onHide={() => setShowUnBlockUserModal(false)}  // Function to close the modal
        userData={userData}
        getUserInfo={getUserInfo}
        client={client}
      />
    </>
  )
}

export default ActionButtons
