import React, { useState } from 'react'

import ProductDropdown from './../../../widgets/custom_dropdown'

const CURRENCY_OPTIONS = [
  { label: 'INR', value: 'inr' },
  { label: 'USD', value: 'usd' }
]

const initialStates = {
  currency_code: CURRENCY_OPTIONS.find(v => v.value === 'usd')
}

const CurrencyDropdown = ({ onStateChange }) => {

  const [state, setState] = useState(initialStates)

  const onCurrencyChange = (selectedOption) => {
    setState((prevState) => ({
      ...prevState,
      currency_code: selectedOption
    }))

    onStateChange('currency_code', selectedOption.value)
  }

  return (
    <div className="currency-dropdown">
      <label>Select Currency</label>
      <ProductDropdown
        options={CURRENCY_OPTIONS}
        placeholder={'Select Currency'}
        value={state.currency_code}
        onSelectOption={onCurrencyChange}
        searchable={false}
      />
    </div>
  )
}

export default CurrencyDropdown