import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'

import rootReducer from './reducers/root'

const persistedState = localStorage.getItem(APP_NAME) ? JSON.parse(localStorage.getItem(APP_NAME)) : { user: { name: 'Aditya' } }

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  preloadedState: persistedState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  enhancers: []
})

export default store