import React from 'react'

import AttachProduct from './components/attach_product'
import DetachProduct from './components/detach_product'
import UserPlansAndActiveSubs from './components/users_plans_and_active_subs'

const PlanTab = ({ userData, client, getUserInfo }) => {

  const userHasCustomProducts = userData.tags.includes('custom plans')

  return (
    <div className='plan-tab'>
      <div className='plan-tab-headers'>
        <AttachProduct
          userData={userData}
          getUserInfo={getUserInfo}
          client={client}
        />
        {
          userHasCustomProducts
            ? <DetachProduct
              userData={userData}
              getUserInfo={getUserInfo}
              client={client}
            />
            : null
        }
      </div>
      <div className='plan-tab__body'>
        <UserPlansAndActiveSubs
          client={client}
          userData={userData}
        />
      </div>
    </div>
  )
}

export default PlanTab
