import React from 'react'
import { Form, Button } from 'react-bootstrap'

const CustomInput = ({
  type = 'text',
  value,
  onValueChange,
  min = 0,
  max = Number.POSITIVE_INFINITY,
  onClearButtonClick
}) => {

  const onInputChange = (e) => {
    e.preventDefault()
    let val = e.target.value
    onValueChange(val)
  }

  return (
    <div className='product-custom-input'>
      <Form.Control
        type={type}
        value={value}
        onChange={onInputChange}
        min={min}
        max={max}
      />
      <Button
        className='product-input-clear-btn'
        onClick={onClearButtonClick}
      >
        <i className="fa-solid fa-arrows-rotate"></i>
      </Button>
    </div>
  )
}

export default CustomInput