import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const workinProgress = () => {
  toast.warning('Work in progress' );

  return (
    <>
      <ToastContainer />
    </>
  );
};

export default workinProgress;
