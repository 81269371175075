import React from 'react'
import classnames from 'classnames'
import { Card, ListGroup, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { CURRENCY } from '../../../../helpers/constants'
import CustomOverlayTrigger from '../../../widgets/overlay_trigger'


const PlanCard = ({ product }) => {

  const isBestValue = product.is_best_value
  const isCustom = product.is_custom

  const plan_id = product._id
  const planName = product.group_display_name
  const FREEMIUM_PRODUCT_ID = '241095070400180595110500'
  const credits = product.credits
  const currency = product.price.currency
  const tooltipText = product.renewal.unit === 'year' ? '20% less than One Time' : '10% less than One Time';

  return (
    <div className="d-flex justify-content-center">
      <Card className={classnames({ 'card-tag': isBestValue || isCustom })}>
        <div className={classnames({ 'card-tag__best-value': isBestValue || isCustom })}>
          {isBestValue ? 'Best Value' : ''}
          {isCustom ? 'Custom' : ''}
        </div>
        <Card.Header className='card-header'>
          <div className='header-title'>
            <div className='plan-name'>
              {planName}
              {plan_id !== FREEMIUM_PRODUCT_ID
                ? <span className=''>{credits} Credits </span>
                : null
              }
            </div>
            <div className='header-subtitle'>
              {product.tagline}
            </div>
          </div>
          {plan_id !== FREEMIUM_PRODUCT_ID ?
            <div className="price-container">
              <div className="strike-price">
                {CURRENCY[currency].symbol}{product.price.one_time}
              </div>&nbsp;
              <CustomOverlayTrigger
                content={tooltipText}
                placement={'top'}
              >
                <div
                  className="normal-price"
                  data-tip="true"
                  data-for="strike-price"
                >
                  {CURRENCY[currency].symbol}{product.price.per_month}<span className='month'>/month</span>
                </div>
              </CustomOverlayTrigger>

            </div>
            : null}
          <Button disabled >
            Subscribe
          </Button>
        </Card.Header>
        <ListGroup>
          <ListGroup.Item>
            <i className='fa fa-check'></i>
            {product.description}
          </ListGroup.Item>
          {
            Object.keys(product.groups)
              .map((groupKey) => (
                <React.Fragment key={groupKey}>
                  <ListGroup.Item className="clickable_item">
                    {product.groups[groupKey].display_name}
                  </ListGroup.Item>
                  {
                    product.features
                      .filter(feature => feature.group === groupKey)
                      .map((feature, index) => (
                        <ListGroup.Item key={index}>
                          <FontAwesomeIcon icon={feature.checked ? 'fa-solid fa-check' : 'fa fa-times'} />
                          {feature.description}
                        </ListGroup.Item>
                      ))
                  }
                </React.Fragment>
              ))
          }
        </ListGroup>
      </Card >
    </div>
  )

}

export default PlanCard