import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import * as Constants from '../../../../../helpers/constants'
import showToastNotification from '../../../../widgets/toastify'
import { ApiHelper } from '../../../../../helpers/apihelper';

const initialState = {
  amount: '',
  credits: '',
  description: '',
  paymentGateway: null,
  otherPaymentGateway: '',
  showModal: false,
  errors: {},
  isApiCallInProgress: false
}

function MarkAsPaid({ data, getUserInfo, getInvoices, client }) {
  const [state, setState] = useState(initialState);
  const id = useSelector((state) => state.user.id);
  const [showOtherInput, setShowOtherInput] = useState(false);

  const options = [
    { value: 'PayPal', label: 'PayPal' },
    { value: 'Stripe', label: 'Stripe' },
    { value: 'Paytm', label: 'Paytm' },
    { value: 'GPay', label: 'GPay' },
    { value: 'Others', label: 'Others' },
  ];

  const validateForm = () => {
    let err = {}
    if (state.credits.toString().length > 10) {
      err['credits'] = 'Please enter credits less than 10 chars'
    }
    if (state.credits <= 0) {
      err['credits'] = 'Please enter credits more than 1 chars';
    }
    if (state.amount.toString().length > 7) {
      err['amount'] = 'Please enter amount less than 7 chars'
    }
    if (state.amount <= 0) {
      err['amount'] = 'Please enter amount more than 1 chars';
    }
    if (Object.keys(err).length) {
      setState((prevState) => ({ ...prevState, errors: err }))
      setTimeout(() => {
        setState((prevState) => ({ ...prevState, errors: {} }))
      }, 5000);
      return false
    }
    return true
  }

  const handleSaveChanges = () => {
    if (validateForm()) {
      if (state.isApiCallInProgress) {
        return;
      }
      let requestBody = {
        user_id: id,
        cop_user_id: data.user_id,
        co_user_id: data.user_id,
        amount: state.amount,
        payment_type: 'fixed',
        credits: state.credits,
        payment_gateway: state.paymentGateway.value === 'Others' ? state.otherPaymentGateway : state.paymentGateway.value,
        description: state.description,
        client_secret: Constants.CLIENT_SECRET,
      };

      let body = _.pick(requestBody, ApiHelper(client, 'GENERATE_RECEIPT').API_BODY)

      setState(prevState => ({ ...prevState, isApiCallInProgress: true }));
      ApiHelper(client, 'GENERATE_RECEIPT').API(client, body)
        .then((response) => {
          if (response.status === Constants.CO_API.FAILED || typeof response === 'string') {
            showToastNotification({
              message: 'Failed',
              isSuccess: false,
            });
          } else if (response.status === Constants.CO_API.SUCCESS || typeof response === 'object') {
            showToastNotification({
              message: data.name + '\'s payment is marked as paid successfully',
              isSuccess: true,
            });
            getUserInfo()
            getInvoices()
            setState(initialState)
          }
        })
        .catch((error) => {
          console.log('generate receipt faild: ', error)
          showToastNotification({
            message: error.message,
            isSuccess: false,
          });
        })
        .finally(() => {
          setState(prevState => ({ ...prevState, isApiCallInProgress: false }));
        });
    }
  }
  const handleOnchange = (selectedOption) => {
    if (selectedOption !== null) {
      setState((prevState) => ({ ...prevState, paymentGateway: selectedOption }));
      if (selectedOption.value === 'Others') {
        setShowOtherInput(true);
      } else {
        setShowOtherInput(false);
      }
    } else {
      setState((prevState) => ({ ...prevState, paymentGateway: null }));
      setShowOtherInput(false);
    }
  }

  const handleShowModal = () => {
    setState(prevState => ({ ...prevState, showModal: true }));
  };

  const handleCloseModal = () => setState(initialState);

  return (
    <>
      <Button
        variant="outline-primary"
        onClick={handleShowModal}
      >
        Generate Receipt
      </Button>
      <Modal
        show={state.showModal}
        onHide={handleCloseModal}
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title className='fs-6 fw-bold'>
            Create Payment Invoice for {data?.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-bg-color'>
          <Form>
            <div className='row'>
              <Form.Group className='d-flex'>
                <div className='col-md-3'>
                  <Form.Label className='w-100 py-2'>Amount:</Form.Label>
                </div>
                <div className='col-md-9'>
                  <Form.Control
                    type='number'
                    placeholder='Enter Amount'
                    value={state.amount}
                    onChange={(e) => {
                      setState((prevState) => ({ ...prevState, amount: e.target.value }));
                    }}
                  />
                  {state.errors ? <p className='text-danger'>{state.errors.amount}</p> : null}
                </div>
              </Form.Group>
            </div>
            <div className='row'>
              <Form.Group className='d-flex'>
                <div className='col-md-3'>
                  <Form.Label className='w-100 py-2'>Credits:</Form.Label>
                </div>
                <div className='col-md-9'>
                  <Form.Control
                    type='number'
                    placeholder='Enter Credits'
                    value={state.credits}
                    onChange={(e) =>
                      setState((prevState) => ({ ...prevState, credits: e.target.value }))
                    }
                  />
                  {state.errors ? <p className='text-danger'>{state.errors.credits}</p> : null}
                </div>
              </Form.Group>
            </div>
            <div className='row'>
              <Form.Group className='d-flex'>
                <div className='col-md-3'>
                  <Form.Label className='w-100 py-2'>Description:</Form.Label>
                </div>
                <div className='col-md-9'>
                  <Form.Control
                    type='text'
                    placeholder='Enter Description'
                    maxLength={50}
                    value={state.description}
                    onChange={(e) =>
                      setState((prevState) => ({ ...prevState, description: e.target.value }))
                    }
                  />
                </div>
              </Form.Group>
            </div>
            <div className='row'>
              <Form.Group className='d-flex'>
                <div className='col-md-3'>
                  <Form.Label className='w-100 py-2'>Payment Gateway:</Form.Label>
                </div>
                <div className='col-md-9'>
                  <Select
                    options={options}
                    isClearable={true}
                    searchable={true}
                    placeholder='Select Payment Gateway'
                    value={state.paymentGateway}
                    onChange={handleOnchange}
                  />
                </div>
              </Form.Group>
            </div>
            {showOtherInput && (
              <div className='row'>
                <Form.Group className='d-flex'>
                  <div className='col-md-3'>
                    {/* <Form.Label className='w-100 py-2'>Other Payment Gateway:</Form.Label> */}
                  </div>
                  <div className='col-md-9'>
                    <Form.Control
                      type='text'
                      placeholder='Payment Gateway'
                      value={state.otherPaymentGateway}
                      onChange={(e) =>
                        setState((prevState) => ({ ...prevState, otherPaymentGateway: e.target.value }))
                      }
                    />
                  </div>
                </Form.Group>
              </div>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='success'
            onClick={handleSaveChanges}
            disabled={
              !state.credits ||
              !state.amount ||
              !state.description ||
              !state.paymentGateway ||
              (state.paymentGateway.value === 'Others' && !state.otherPaymentGateway) ||
              state.credits < 0 ||
              state.amount < 0 ||
              state.isApiCallInProgress
            }
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MarkAsPaid;
