import ApiService from '../apiservice'

export default {
  USER_LIST: {
    API: ApiService.getClearoutPhoneUsersList,
    API_BODY: ['skip', 'limit', 'search', 'sort', 'filters', 'client_secret']
  },
  USER_ACTIVITY: {
    API: ApiService.getCopUsersActivities,
    API_BODY: ['co_user_id', 'skip', 'limit', 'search', 'sort', 'filters', 'client_secret']
  },
  CREATE_USER: {
    API: ApiService.createClearoutPhoneUser,
    API_BODY: ['email', 'phone', 'client_secret']
  },
  USER_INFO: {
    API: ApiService.getCopUserInfo,
    API_BODY: ['client_secret', 'user_id']
  },
  EDIT_EMAIL: {
    API: ApiService.clearoutPhoneUpdateUserEmail,
    API_BODY: ['cop_user_id', 'user_id', 'email', 'client_secret']
  },
  UPDATE_TIMEZONE: {
    API: ApiService.clearoutPhoneUpdateTimezone,
    API_BODY: ['cop_user_id', 'timezone', 'client_secret', 'crm_user_data']
  },
  ADD_BONUS: {
    API: ApiService.giveBonusCopUser,
    API_BODY: ['client_secret', 'cop_user_id', 'created_by', 'credits', 'description', 'crm_user_data']
  },
  RESET_BONUS: {
    API: ApiService.resetBonusCopUser,
    API_BODY: ['client_secret', 'cop_user_id', 'created_by', 'description', 'crm_user_data']
  },
  ADDITIONAL_INFO: {
    API: ApiService.getClearoutPhoneUserProfileAdditionalInfo,
    API_BODY: ['client_secret', 'cop_user_id']
  },
  UPDATE_API_RATE_LIMIT: {
    API: ApiService.updateRateLimitForCOPIndividual,
    API_BODY: ['api_rate_limits', 'cop_user_id', 'reason', 'user_id', 'client_secret', 'crm_user']
  },
  ADD_DAILY_LIMIT: {
    API: ApiService.clearoutPhoneAddDailyLimit,
    API_BODY: ['cop_user_id', 'crm_user_id', 'daily_verify_limit', 'reason', 'client_secret', 'crm_user_data']
  },
  REMOVE_DAILY_LIMIT: {
    API: ApiService.clearoutPhoneUsersRemoveDailyLimit,
    API_BODY: ['cop_user_id', 'crm_user_id', 'affiliate', 'client_secret', 'crm_user_data']
  },
  UPDATE_DAILY_LIMIT: {
    API: ApiService.clearoutPhoneUpdateDailyLimit,
    API_BODY: ['cop_user_id', 'crm_user_id', 'daily_verify_limit', 'client_secret', 'crm_user_data', 'reason']
  },
  RECEIPT_LIST: {
    API: ApiService.listCopInvoices,
    API_BODY: ['client_secret', 'cop_user_id']
  },
  GENERATE_RECEIPT: {
    API: ApiService.clearoutPhoneMarkAsPaid,
    API_BODY: ['cop_user_id', 'user_id', 'amount', 'payment_type', 'credits', 'payment_gateway', 'description', 'client_secret']
  },
  LOGOUT_SESSIONS: {
    API: ApiService.logoutCopAllSessionOfClearoutUser,
    API_BODY: ['cop_user_id', 'session_type', 'client_secret', 'crm_user_data']
  },
  BLOCK_USER: {
    API: ApiService.blockClearoutPhoneUser,
    API_BODY: ['cop_user_id', 'user_id', 'reason', 'client_secret', 'crm_user_data']
  },
  UNBLOCK_USER: {
    API: ApiService.unBlockClearoutPhoneUser,
    API_BODY: ['cop_user_id', 'user_id', 'reason', 'client_secret', 'crm_user_data']
  },
  DELETE_ACCOUNT: {
    API: ApiService.deleteClearoutPhoneUser,
    API_BODY: ['cop_user_id', 'reason', 'client_secret', 'crm_user_id']
  },
  FORGOT_PASSWORD_LINK: {
    API: ApiService.copResetTokens,
    API_BODY: ['user_id', 'action_type', 'client_secret', 'crm_user_id', 'crm_user_data']
  },
  SIGN_UP_LINK: {
    API: ApiService.copResetTokens,
    API_BODY: ['user_id', 'action_type', 'client_secret', 'crm_user_id', 'crm_user_data']
  },
  USER_LIST_DOWNLOAD: {
    API: ApiService.getClearoutPhoneUsersList,
    API_BODY: ['skip', 'limit', 'search', 'sort', 'filters', 'download', 'client_secret']
  },
  GET_ABUSE_LIST_WRT_COUNTRY: { // GET_COUNTRIES
    API: ApiService.getCopAbuseListWrtCountry,
    API_BODY: ['country', 'client_secret']
  },
  ADD_ABUSE_ENTRY: {
    API: ApiService.addCopAbuseListEntry,
    API_BODY: ['type', 'value', 'reason', 'block_free_email', 'client_secret', 'crm_user_data']
  },
  SIGN_UP_ABUSE_LIMITTER: {
    API: ApiService.copSignUpAbuseLimiter,
    API_BODY: ['domain', 'ip', 'action', 'email', 'crm_user_data', 'client_secret']
  },
  GET_ABUSE_LIST: {
    API: ApiService.getCopAbuseList,
    API_BODY: ['skip', 'limit', 'search', 'sort', 'abuse_id', 'client_secret']
  },
  COUPONS_LIST: {
    API: ApiService.listCopCoupons,
    API_BODY: ['skip', 'limit', 'search', 'sort', 'client_secret']
  },
  CREATE_COUPONS: {
    API: ApiService.createCopCoupon,
    API_BODY: ['name', 'coupon', 'expiry', 'status', 'user_id', 'description', 'affiliated_to', 'coupon_type', 'allowed_redeem_count',
      'discount_type', 'discount_min', 'discount_max', 'discount_value', 'client_secret', 'credits', 'daily_verify_limit']
  },
  UPDATE_COUPONS: {
    API: ApiService.editCopCoupon,
    API_BODY: ['name', 'coupon', 'expiry', 'status', 'user_id', 'description', 'affiliated_to', 'coupon_type', 'allowed_redeem_count',
      'discount_type', 'discount_min', 'discount_max', 'discount_value', 'client_secret', 'credits', 'daily_verify_limit']
  },
  UPDATE_ABUSE_ENTRY: {
    API: ApiService.updateCopAbuseListEntry,
    API_BODY: ['type', 'value', 'reason', 'block_free_email', 'abuse_id', 'exempted', 'client_secret', 'crm_user_data']
  },
  GET_ABUSE_LOGS: {
    API: ApiService.getClearoutPhoneAbuseActivityLogs,
    API_BODY: ['skip', 'limit', 'search', 'sort', 'filters', 'client_secret']
  },
  GET_ABUSE_UPDATE_ACTIVITY: {
    API: ApiService.getClearoutPhoneAbuseUpdateLogs,
    API_BODY: ['skip', 'limit', 'search', 'sort', 'filters', 'client_secret']
  },
  REMOVE_API_RATE_LIMIT: {
    API: ApiService.removeRateLimitForCOPIndividual,
    API_BODY: ['api_names', 'cop_user_id', 'reason', 'user_id', 'client_secret', 'crm_user']
  },
  ALLOWED_DAILY_LIMIT: {
    API: ApiService.clearoutPhoneAddDailyLimit,
    API_BODY: ['cop_user_id', 'crm_user_id', 'daily_verify_limit', 'reason', 'client_secret', 'crm_user_data']
  }
}