import React from 'react'
import Select from 'react-select'


const CustomDropdown = ({
  options,
  placeholder,
  value,
  searchable,
  onSelectOption,
  name = 'custom_dropdown'
}) => {

  const onChange = (selectedOption, e) => {
    onSelectOption(selectedOption, e)
  }

  return (
    <Select
      name={name}
      options={options}
      placeholder={placeholder}
      value={value}
      isSearchable={searchable}
      onChange={onChange}
      className="product-custom-select"
    />
  )
}

export default CustomDropdown