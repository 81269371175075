import React, { useState } from 'react'

import ProductDropdown from './../../../widgets/custom_dropdown'

const PRODUCT_TYPE_OPTIONS = [
  { label: 'Plan', value: 'plan' },
  { label: 'Addon', value: 'addon' }
]

const initialStates = {
  product_type: PRODUCT_TYPE_OPTIONS.find(v => v.value === 'plan')
}

const PrdouctTypeDropdown = ({ onStateChange }) => {

  const [state, setState] = useState(initialStates)

  const onProductTypeChange = (selectedOption) => {
    setState((prevState) => ({
      ...prevState,
      product_type: selectedOption
    }))

    onStateChange('product_type', selectedOption.value)
  }

  return (
    <div className="product-type-dropdown">
      <label>Select Plan Type</label>
      <ProductDropdown
        options={PRODUCT_TYPE_OPTIONS}
        placeholder={'Select Plan Type'}
        value={state.product_type}
        onSelectOption={onProductTypeChange}
        searchable={false}
      />
    </div>
  )
}

export default PrdouctTypeDropdown