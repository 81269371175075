import React from 'react'
import CreatableSelect from 'react-select/creatable'


const CreatableProductDropdown = ({
  options,
  placeholder,
  value,
  searchable,
  onSelectOption,
  onCreateOption,
  formatCreateLabel,
  isMulti,
  isClearable,
  components,
  menuIsOpen,
  onInputChange
}) => {

  return (
    <CreatableSelect
      options={options}
      placeholder={placeholder}
      value={value}
      isSearchable={searchable}
      onChange={onSelectOption}
      onCreateOption={onCreateOption}
      createOptionPosition="first"
      formatCreateLabel={formatCreateLabel}
      isMulti={isMulti}
      isClearable={isClearable}
      components={components}
      menuIsOpen={menuIsOpen}
      onInputChange={onInputChange}
    />
  )
}

export default CreatableProductDropdown