import React, { useState } from 'react'
import { Button } from 'react-bootstrap'

import AttachProductModal from './attach_product_modal'


const initialState = {
  attachProductModalOpen: false
}

const AttachProduct = ({
  client,
  userData,
  getUserInfo,
}) => {

  const [state, setState] = useState(initialState)

  const onClickAttachProduct = () => {
    setState((prevState) => ({
      ...prevState,
      attachProductModalOpen: !prevState.attachProductModalOpen
    }))
  }

  const onAttachProductModalClose = () => {
    setState(initialState)
  }

  return (
    <>
      <Button
        className='attach-product-btn'
        variant='outline-primary'
        onClick={onClickAttachProduct}
      >
        Attach Plan
      </Button>
      <AttachProductModal
        attachProductModalOpen={state.attachProductModalOpen}
        onAttachProductModalClose={onAttachProductModalClose}
        userData={userData}
        getUserInfo={getUserInfo}
        client={client}
      />
    </>
  )
}

export default AttachProduct