import React, { useState, useEffect, useCallback } from 'react'
import { Button, FormControl } from 'react-bootstrap'
import Select from 'react-select'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import _ from 'lodash'

import FilterDropdownComponent from './filter_dropdown'
import FilterTagComponent from './filter_tag'
import * as Constants from '../../../helpers/constants'
import { ApiHelper } from '../../../helpers/apihelper';

import 'bootstrap-daterangepicker/daterangepicker.css';
import './../../../static/css/filters.css'
import './../../../static/css/daterangepicker.css'

const _filterReducer = ({ type, filter, state }) => {
  if (type === 'default') {
    return filter
  }
  if (type === 'remove') {
    delete state[filter.filter_value]
    return _.assign({}, state)
  }
  else if (type === 'reset') {
    state = _.assign({}, filter)
    return state
  }
  return {
    ...state,
    [filter.filter_value]: {
      value: filter.value,
      label: filter.label,
      filter_label: filter.filter_label
    }
  }
}

const initialstate = {
  selectedValue: 'Last 7 days (Including Today)',
  orgnizationFiltersList: [],
}

const FiltersComponent = (props) => {

  const [state, setState] = useState(initialstate)


  const {
    filterState,
    onSetFilters,
    onSearch,
    clearSearch,
    searchValue,
    filters,
    filtersToInclude,
    className = 'filter-container',
    skipFilter = [],
    resetFilters,
    client
  } = props

  useEffect(() => {
    setState(initialstate)
  }, [client])

  //organization api call
  const getOrganizationNames = useCallback((skip, limit, sorting) => {
    let requestBody = {
      skip: skip,
      limit: limit,
      sort: sorting,
    }

    let body = _.pick(requestBody, ApiHelper(client, 'ORGANIZATION_NAMES').API_BODY)

    ApiHelper(client, 'ORGANIZATION_NAMES').API(client, body)
      .then((res) => {
        if (res.status === Constants.CO_API.SUCCESS) {
          setState(prevState => ({ ...prevState, orgnizationFiltersList: res.data.data }))
        }
      })
      .catch((err) => {
        console.log('org list api err', err)
      })
  }, [client])

  useEffect(() => {
    if (_.includes(filtersToInclude, 'org_filter') && client)
      getOrganizationNames(0, 200, { created_on: 'desc' })
  }, [getOrganizationNames, filtersToInclude, client])

  // const filters = Constant.SUBSCRIPTION_FILTERS[client]


  //organisation filter values
  const options = state.orgnizationFiltersList && state.orgnizationFiltersList.map((item) => ({
    value: item.id,
    label: item.name,
  }));


  const onSelectFilter = (eventKey) => {
    if (eventKey) {
      onSetFilters(_filterReducer({ type: 'add', filter: eventKey, state: filterState }))
    }
  }

  const onOrgnizationFilterSelect = (event) => {
    let org_filter = {
      value: event?.value,
      label: '',
      filter_label: event?.label,
      filter_value: 'org_filter'
    }
    onSetFilters(_filterReducer({ type: 'add', filter: org_filter, state: filterState }))
  }

  const onRemoveFilter = (key, filter) => {
    let removed_filter = {
      value: '',
      label: '',
      filter_label: filter.filter_label,
      filter_value: key
    }
    onSetFilters(_filterReducer({ type: 'remove', filter: removed_filter, state: filterState }))
  }

  const onResetFilters = () => {
    onSetFilters(_filterReducer({
      type: 'reset',
      filter: resetFilters,
      state: filterState
    }))
    setState(prevState => ({ ...prevState, selectedValue: 'Last 7 days (Including Today)' }))
  }

  const handleDateRangeApply = (event, picker) => {
    const selectedPreset = picker.chosenLabel;
    let selectedValue = '';

    if (selectedPreset === 'Custom Range') {
      const startDate = picker.startDate.format('DD MMM YYYY');
      const endDate = picker.endDate.format('DD MMM YYYY');
      selectedValue = `${startDate} - ${endDate}`;
    } else {
      selectedValue = selectedPreset === 'All' ? 'All' : selectedPreset;
    }

    setState(prevState => ({ ...prevState, selectedValue }));

    let valueObj = null;

    if (selectedPreset !== 'All') {
      if (selectedPreset === 'Custom Range') {
        valueObj = {
          key: 'created_on',
          start_date: picker.startDate.toISOString(),
          end_date: picker.endDate.toISOString()
        };
      } else {
        valueObj = {
          key: 'created_on',
          preset: Constants.DATE_RANGE_PRESETS[selectedPreset]
        };
      }
    }

    let date_filter = {
      value: valueObj,
      label: '',
      filter_label: 'date_range',
      filter_value: 'date_range'
    };
    onSetFilters(_filterReducer({ type: 'add', filter: date_filter, state: filterState }));
  };

  return (
    <div className={className}>
      {
        _.includes(filtersToInclude, 'main_filters')
          ?
          <div className="filter-comp">
            <FilterDropdownComponent
              filters={filters}
              onSelectFilter={onSelectFilter}
            />
            <div className="list-selected-filters">
              <FilterTagComponent
                selectedFilters={filterState}
                onRemoveSelectedFilter={onRemoveFilter}
                skipFilter={skipFilter}
              />
            </div>
          </div>
          : null
      }
      {
        _.includes(filtersToInclude, 'daterange_filter')
          ?
          <div className='day-filter mx-1'>
            <DateRangePicker
              initialSettings={{
                autoUpdateInput: false,
                locale: { format: 'YYYY-MM-DD' },
                ranges: Constants.RANGES,
                maxDate: moment()
              }}
              onApply={handleDateRangeApply}
            >
              <FormControl
                type="text"
                className='cursor-pointer date-range-input'
                value={state.selectedValue}
                readOnly={true}
              />
            </DateRangePicker>
          </div>
          : null
      }
      {
        _.includes(filtersToInclude, 'reset_filter')
          ?
          <Button
            variant="secondary"
            className='filter-btn'
            size="md"
            onClick={onResetFilters}
          >
            Reset
          </Button>
          : null
      }
      {
        _.includes(filtersToInclude, 'org_filter')
          ?
          <div>
            <Select
              onChange={onOrgnizationFilterSelect}
              options={options}
              isClearable={true}
              searchable={true}
              placeholder="Select Organization"
              className='org-filter mx-1'
            />
          </div>
          : null
      }
      {
        _.includes(filtersToInclude, 'search_filter')
          ?
          <div className='search-container px-1'>
            <FormControl
              type="text"
              placeholder="Search..."
              onChange={onSearch}
              value={searchValue}
            />
            {searchValue && (
              <button
                className='clear-button'
                onClick={clearSearch}
              >
                x
              </button>
            )}
          </div>
          : null
      }
    </div>
  )
}

FiltersComponent.displayName = 'FiltersComponent'

export default FiltersComponent;
