import React, { useState } from 'react'
import { Button } from 'react-bootstrap'

import DetachProductModal from './detach_product_modal'


const initialState = {
  detachProductModalOpen: false
}

const DetachProduct = ({ userData, getUserInfo, client }) => {

  const [state, setState] = useState(initialState)

  const onClickDetachProduct = () => {
    setState((prevState) => ({
      ...prevState,
      detachProductModalOpen: !prevState.detachProductModalOpen
    }))
  }

  const onDetachProductModalClose = () => {
    setState(initialState)
  }

  return (
    <>
      <Button
        className='detach-product-btn'
        variant="outline-primary"
        onClick={onClickDetachProduct}
      >
        Detatch Plan
      </Button>
      <DetachProductModal
        detachProductModalOpen={state.detachProductModalOpen}
        onDetachProductModalClose={onDetachProductModalClose}
        userData={userData}
        getUserInfo={getUserInfo}
        client={client}
      />
    </>
  )
}

export default DetachProduct