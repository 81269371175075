import React, { useState } from 'react'

import PaymentSettingsModal from './payment_settings_modal'

const initialState = {
  isPaymentSettingsModalOpen: false
}

function PaymentSettings({ userData, getUserInfo, client }) {
  const [state, setState] = useState(initialState)

  const onClickPaymentSettings = () => {
    setState((prevState) => ({
      ...prevState,
      isPaymentSettingsModalOpen: !state.isPaymentSettingsModalOpen
    }))
  }

  const onClosePaymentSettingsModal = () => {
    setState(initialState)
  }

  return (
    <>
      <p
        onClick={onClickPaymentSettings}
        className='m-0 text-wrap'
      >
        Payment Settings
      </p>
      {
        state.isPaymentSettingsModalOpen
          ? <PaymentSettingsModal
            showModal={state.isPaymentSettingsModalOpen}
            closeModal={onClosePaymentSettingsModal}
            userData={userData}
            getUserInfo={getUserInfo}
            client={client}
          />
          : null
      }
    </>
  )
}

export default PaymentSettings
