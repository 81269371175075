import React, { Suspense, lazy } from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';

import RouteLoader from '../../helpers/routeLoader';
import { UserIsNotAuthenticated } from './../../authenticators'

import clearoutSpokeslyRouter from './co_spokesly_routes'
import clearoutRouter from './clearout_routes';
import clearoutphoneRouter from './clearoutphone_routes';
import clearoutEuropeRouter from './co_eu_routes';
import clearoutEnterpriseRouter from './co_ep_routes';
import clearoutCamsRouter from './co_cams_routes';
import clearoutPepipostRouter from './co_pepipost_routes';

const Dashboard = lazy(() => import('./dashboard'))


const router = createBrowserRouter([
  {
    id: 'dashboard-route',
    element:
      <UserIsNotAuthenticated redirectPath='/login'>
        <Suspense fallback={<RouteLoader />}>
          <Dashboard />
        </Suspense>
      </UserIsNotAuthenticated>,
    children: [
      // clearout routes
      ...clearoutRouter.routes,

      // clearoutphone routes
      ...clearoutphoneRouter.routes,

      // spokesly routes
      ...clearoutSpokeslyRouter.routes,

      // europe routes
      ...clearoutEuropeRouter.routes,

      // enterprise routes
      ...clearoutEnterpriseRouter.routes,

      // cams routes
      ...clearoutCamsRouter.routes,

      // pepipost routes
      ...clearoutPepipostRouter.routes,

      // redirect
      {
        id: 'dashboard-redirect',
        path: '*',
        element:
          <Navigate
            to={'/clearout/users'}
            replace={true}
          />
      },



    ]
  }
])

export default router
