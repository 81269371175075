import React from 'react'

import ProductDropdown from './../../../widgets/custom_dropdown'

const AddonProductNamesDropdown = ({
  addonNameOptions,
  onSelectedProduct,
  product_name
}) => {

  return (
    <div className="product-addon-names-dropdown">
      <label>Select Addon Plan</label>
      <ProductDropdown
        options={addonNameOptions}
        placeholder={'Select Addon Plan'}
        value={product_name}
        onSelectOption={onSelectedProduct}
      />
    </div>
  )
}

export default AddonProductNamesDropdown