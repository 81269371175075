import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TimezonePicker from 'react-bootstrap-timezone-picker';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import * as Constants from '../../helpers/constants'
import showToastNotification from '../widgets/toastify';
import { ApiHelper } from '../../helpers/apihelper';

import 'react-toastify/dist/ReactToastify.css';
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';

const initialState = {
  selectedTimezone: '',
  showModal: false,
  isApiCallInProgress: false
}

function UpdateTimezoneModal({ data, couserid, getUserInfo, client }) {
  const [state, setState] = useState({
    ...initialState,
    selectedTimezone: data.locale.timezone
  });

  const userName = useSelector(state => state.user.name);

  const handleSaveChanges = () => {
    if (state.isApiCallInProgress) {
      return;
    }
    let requestBody = {
      co_user_id: couserid,
      cop_user_id: couserid,
      timezone: state.selectedTimezone,
      crm_user_data: {
        name: userName
      },
      client_secret: Constants.CLIENT_SECRET
    }

    let body = _.pick(requestBody, ApiHelper(client, 'UPDATE_TIMEZONE').API_BODY)

    setState(prevState => ({ ...prevState, isApiCallInProgress: true }));

    ApiHelper(client, 'UPDATE_TIMEZONE').API(client, body)
      .then((response) => {
        if (response.status === Constants.CO_API.SUCCESS) {
          showToastNotification({
            message: 'Timezone updated successfully',
            isSuccess: true,
          });
          setState((prevState) => ({
            ...prevState,
            showModal: false,
          }));
          getUserInfo();
        } else {
          showToastNotification({
            message: 'failed to update timezone',
            isSuccess: false,
          });
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setState(prevState => ({ ...prevState, isApiCallInProgress: false }));
      });
  }

  const handleShowModal = () => {
    setState(prevState => ({ ...prevState, showModal: true }));
  };

  const handleCloseModal = () => {
    setState(prevState => ({ ...prevState, selectedTimezone: data.locale.timezone, showModal: false }))
  }

  return (
    <>
      <Button
        onClick={handleShowModal}
        variant='outline-primary'
        size="sm"
        className='sm-btns'
      >
        <FontAwesomeIcon
          icon="fa fa-pencil"
        />
      </Button>
      <Modal
        show={state.showModal}
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title className='fs-6 fw-bold'>Update Timezone</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-bg-color'>
          <Form>
            <div className='row'>
              <Form.Group
                className='d-flex'
              >
                <div className='col-md-5'>
                  <Form.Label className='w-100 py-2'>Select Timezone:</Form.Label>
                </div>
                <div className='col-md-7'>
                  <TimezonePicker
                    value={state.selectedTimezone}
                    onChange={(timezone) => setState(prevState => ({ ...prevState, selectedTimezone: timezone }))}
                  />
                </div>
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={handleSaveChanges}
            disabled={!state.selectedTimezone || state.isApiCallInProgress}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdateTimezoneModal;
