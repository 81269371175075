import React from 'react'

import CurrencyDropdown from './../dropdowns/currency_dropdown'
import ProdcutIntervalDropdowns from '../dropdowns/interval_dropdowns'
import CreditsIntervalDropdowns from '../dropdowns/credit_interval_dropdowns'
import ProductAmountInput from '../inputs/amount_input'
import ProductCreditsInput from '../inputs/credits_input'
import ProductIsCustomCheckbox from '../checks/is_custom_checkbox'
import ProductIsRecurringCheckbox from '../checks/recurring_checkbox'
import ResetCreditsCustomCheckbox from '../checks/reset_credit_checkbox'

const CreatePlanProduct = ({ onStateChange, ...props }) => {

  return (
    <div className="plan-product-form">
      <CurrencyDropdown onStateChange={onStateChange} />
      <ProdcutIntervalDropdowns onStateChange={onStateChange} />
      <CreditsIntervalDropdowns onStateChange={onStateChange} />
      <div className="plan-amount-n-credits">
        <ProductAmountInput
          onStateChange={onStateChange}
          {...props}
        />
        <ProductCreditsInput
          onStateChange={onStateChange}
          {...props}
        />
      </div>
      <div className="plan-custom-n-recurring">
        <ProductIsCustomCheckbox onStateChange={onStateChange} />
        <ProductIsRecurringCheckbox
          disabled={true}
          onStateChange={onStateChange}
        />
        <ResetCreditsCustomCheckbox onStateChange={onStateChange} />
      </div>
    </div>
  )
}

export default CreatePlanProduct