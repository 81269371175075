import React from 'react';
import _ from 'lodash'

import CustomInput from './../../../widgets/custom_input';

const initialStates = {
  amount: 0
}

const ProductAmountInput = ({
  onStateChange,
  productInfo,
  amount
}) => {

  const onValueChange = (amount) => {
    onStateChange('amount', amount)
  }

  const onClearButtonClick = () => {
    onValueChange(initialStates.amount)
  }

  let amountLabel = 'Enter Amount (in USD)'

  if (!_.isEmpty(productInfo)) {
    let baseLimit = productInfo.data.base_limit

    amountLabel = `Enter Amount (for ${baseLimit} units in USD)`
  }

  return (
    <div className="product-amount-input">
      <label>{amountLabel}</label>
      <CustomInput
        type="number"
        value={amount}
        onValueChange={onValueChange}
        max={30000}
        onClearButtonClick={onClearButtonClick}
      />
    </div>
  )
}

export default ProductAmountInput