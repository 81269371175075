import React from 'react';
import _ from 'lodash';

import * as IndividualUserConstants from './helpers/constant';
import * as UserInfoFormatters from './helpers/user_info_formatters';

import './../../static/css/profile.css'


const ExtraUserInfo = ({ userData, client, isChecked, additionalData, getUserInfo }) => {

  const getExtraUserInfo = () => {
    const userInfo = IndividualUserConstants.ORG_EXTRA_USER_INFO[client];

    return (
      <>
        {userInfo.map((opts, idx) => {
          const accessorValue = _.get(userData, opts.accessorKey);
          return (
            <div
              key={idx}
              className='col-md-2 mb-2'
            >
              <p className='mb-1'>{opts.label}</p>
              <div>
                {UserInfoFormatters[opts.formatter]({
                  client,
                  value: accessorValue,
                  userData: userData,
                  additionalData: additionalData,
                  isChecked: isChecked,
                  getUserInfo: getUserInfo
                })}
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className='row'>
      {getExtraUserInfo()}
    </div>
  );
};

export default ExtraUserInfo;
