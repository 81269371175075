import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ClearoutIndividualUserInfo from '../individual_user_info/clearout_individual_user_info';

const initialState = {
  showModal: false,
}

function UserInfoModal({ userId, client }) {
  const [state, setState] = useState(initialState)

  const handleShowModal = () => {
    setState(prevState => ({ ...prevState, showModal: true }));
  };

  const handleCloseModal = () => {
    setState(initialState)
  }

  return (
    <>
      <Button
        onClick={handleShowModal}
        size="sm"
        className='sm-btns'
      >
        <FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" />
      </Button>
      <Modal
        show={state.showModal}
        onHide={handleCloseModal}
        size='xl'
      >
        <Modal.Header closeButton>
          <Modal.Title className='fs-6 fw-bold'>{userId} details</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-bg-color'>
          <ClearoutIndividualUserInfo
            client={client}
            userId={userId}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UserInfoModal;
