/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Card, Button } from 'react-bootstrap'
import classnames from 'classnames'
import _ from 'lodash'

import { CURRENCY } from '../../../../helpers/constants'
import { calculateAddonAmount } from '../../helpers/helper'
import QuantityDropdown from '../dropdowns/addon_quantity_dropdown'

const initialState = {
  quantity: 1
}

const AddonCard = ({ product }) => {

  if (!_.isEmpty(product.dropdown_options))
    initialState.quantity = _.first(product.dropdown_options).value

  const [state, setState] = useState(initialState)

  const isCustom = product.is_custom
  const currency = product.price.currency
  const price = product.price.total_price

  const onStateChange = (stateKey, stateValue) => {
    setState((prevState) => ({
      ...prevState,
      [stateKey]: stateValue
    }))
  }

  return (
    <div className="d-flex justify-content-center">
      <Card className={classnames('addon-card', { 'card-tag': isCustom })}>
        <div className={classnames({ 'card-tag__best-value': isCustom })}>
          {isCustom ? 'Custom' : ''}
        </div>
        <Card.Body>
          <div className='addon-content'>
            <div className='addon-details'>
              <div>
                <span className='addon-header'>{product.display_name}</span>
                <span className='addon-description'>{product.description}</span>
              </div>
              <div className='note'>
                <span className='price-value'>{CURRENCY[currency].symbol}{price} </span>
                <span>
                  {product.price_description}
                </span>
              </div>
            </div>
            <div className='price-details'>

              {
                _.isEmpty(product.dropdown_options)
                  ? null
                  :
                  <div className="addon-dropdown">
                    <div className='dropdown-label'>
                      <span>Additional increase</span>
                    </div>
                    <QuantityDropdown
                      onStateChange={onStateChange}
                      dropdownOptions={product.dropdown_options}
                      product={product}
                    />
                  </div>
              }
              <span>
                Total Price:{' '}
                <span className='price-value'>
                  {CURRENCY[currency].symbol}
                  {calculateAddonAmount(product, state.quantity)}
                </span>
              </span>
              <Button disabled>
                {product.recurring ? 'Subscribe' : 'Buy'}
              </Button>
              {/* add bbuy btn */}
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}

export default AddonCard