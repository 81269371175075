import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import * as Helper from '../../helpers/helper';

Chart.register(...registerables);

const LineChart = (props) => {
  const [data, setData] = useState({
    labels: [],
    datasets: [],
  });

  const [options] = useState({
    plugins: {
      datalabels: {
        formatter: () => '',
      },
      tooltips: {
        mode: 'label',
        bodyFontSize: 12,
        bodySpacing: 2,
      },
      legend: {
        display: true,
        position: props.position,
        fontSize: 10,
        labels: {
          boxWidth: 10,
          padding: 8,
        }
      }
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          maxRotation: 45,
          minRotation: 30,
          maxTicksLimit: 24,
        }
      },
      y: {
        title: {
          display: true,
          text: 'Count',
          font: 18
        },
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 5,
        },
      }
    },
    elements: {
      point: {
        radius: 1,
        hitRadius: 10,
        hoverRadius: 4,
        hoverBorderWidth: 3,
      },
    },
  });


  useEffect(() => {
    const formatData = (data) => {
      return data.map((result, index) => {
        return {
          label: result.label,
          data: result.data,
          borderColor: result.borderColor ? result.borderColor : Helper.pickChartColor(index),
          pointHoverBackgroundColor: '#fff',
          fill: result.fill ? result.fill : false,
          borderWidth: 1.5,
          lineTension: 0.4,
          backgroundColor: result.backgroundColor ? result.backgroundColor : Helper.pickAreaChartColor(index),
        };
      });
    };

    const formatLabels = (data) => {
      return data.labels
        ? data.labels.map((label) => {
          if (data.breakdown === 'DAY') {
            return label ? Helper.formatTime(label, 'DD MMM') : '';
          } else if (data.breakdown === 'HOUR') {
            return label
              ? data.labels.length <= 24
                ? Helper.formatTime(label, 'hh A')
                : Helper.formatTime(label, 'DD MMM - hh A')
              : '';
          } else if (data.breakdown === 'MONTH') {
            return label ? Helper.formatTime(label, 'MMM YYYY') : '';
          } else {
            return label ? Helper.formatTime(label, 'DD MMM YY') : '';
          }
        })
        : [];
    };

    if (props.graphData && !props.isGraphDataLoading) {
      const chartData = {
        labels: formatLabels(props.graphData),
        datasets: formatData(props.graphData.values),
      };
      setData(chartData);
    }
  }, [props.graphData, props.isGraphDataLoading, props.service]);


  const hasData = data.datasets.some((dataset) => Array.isArray(dataset.data) && dataset.data.some((value) => Number(value) !== 0));

  return (
    <div style={{ height: '100%' }}>
      {hasData ? (
        <Line
          data={data}
          options={options}
          height={props.height}
        />
      ) : (
        <div className="h-100 text-center d-flex justify-content-center align-items-center flex-column">
          <p className="m-0">No Activity Found</p>
        </div>
      )}
    </div>
  );
}

export default LineChart;
