import React, { useCallback, useState,useContext } from 'react'
import _ from 'lodash'
import { Button } from 'react-bootstrap'

import * as Constants from './../../../helpers/constants'
import showToastNotification from './../../widgets/toastify'

import CreateProductForm from './create_product_form'
import AddonCard from './cards/addon_card'
import PlanCard from './cards/plan_cards'
import { ApiHelper } from '../../../helpers/apihelper'
import AppConfigContext from '../../dashboard/app_config_context'


import './../../../static/css/products/create_product.css'
import './../../../static/css/products/product_cards.css'

const initialState = {
  product: {},
  inputData: null,
  isAPIInprogress: false
}

const CreateProduct = ()=> {

  const [state, setState] = useState(initialState)

  const appConfig = useContext(AppConfigContext)
  const client = appConfig.client

  const getProductToView = (requestQueryParams) => {

    let queryParams = _.pick(requestQueryParams, ApiHelper(client, 'GET_PRODUCT').API_QUERY)

    ApiHelper(client, 'GET_PRODUCT').API(client, queryParams)
      .then((response) => {
        if (response && response.status === Constants.CO_API.SUCCESS) {
          setState((prevState) => ({
            ...prevState,
            product: response.data.data.product,
            inputData: queryParams
          }))
        } else {
          let message = 'Something went wrong !!!'
          if (response.message)
            message = response.message

          showToastNotification({ message, isSuccess: false });
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const resetProduct = useCallback(() => {
    setState(initialState)
  }, [])

  const onClickCreateProduct = () => {
    setState((prevState) => ({
      ...prevState,
      isAPIInprogress: true
    }))

    let requestBody = state.inputData
    let body = _.pick(requestBody, ApiHelper(client, 'CREATE_PRODUCT').API_BODY)

    ApiHelper(client, 'CREATE_PRODUCT').API(client, body)
      .then((response) => {
        if (response && response.status === Constants.CO_API.SUCCESS) {
          let message = 'Plan Created Successfully'
          if (response.message)
            message = response.message
          showToastNotification({ message, isSuccess: true })

        } else {
          let message = 'Something went wrong !!!'
          if (response.message)
            message = response.message

          showToastNotification({ message, isSuccess: false })
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setState((prevState) => ({
          ...prevState,
          isAPIInprogress: false
        }))
      })
  }

  const getProductCard = () => {
    if (_.isEmpty(state.product))
      return null

    if (state.product.type === 'addon')
      return (
        <div className="d-flex flex-column gap-3 p-1">
          <AddonCard product={state.product} />
          <Button
            className="create-product-btn"
            onClick={onClickCreateProduct}
            disabled={state.isAPIInprogress}
          >
            Create Plan
          </Button>
        </div>
      )

    if (state.product.type === 'plan')
      return (
        <div className="d-flex flex-column gap-3 p-1">
          <PlanCard product={state.product} />
          <Button
            className="create-product-btn"
            onClick={onClickCreateProduct}
            disabled={state.isAPIInprogress}
          >
            Create Plan
          </Button>
        </div>
      )
  }

  return (
    <>
      <div id="page-header">
        <h1 className='m-0 header'>
          {Constants.CLIENTS[client.toUpperCase()].DISPLAY_NAME} Create Plan
        </h1>
      </div>
      <div id="page-content">
        <div className="create-product">
          <CreateProductForm
            resetProduct={resetProduct}
            getProductToView={getProductToView}
            client={client}
          />
          <div className="product-view">
            {getProductCard()}
          </div>
        </div>
      </div >
    </>
  )
}

export default CreateProduct