import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';

const ShowMoreButton = ({ showMore, onClick, showLabel, hideLabel }) => {
  return (
    <Button
      onClick={onClick}
      className="crm-show-more-toggle-btn"
    >
      {showMore ? hideLabel : showLabel} {/* Toggle label */}
      <FontAwesomeIcon icon={showMore ? faAngleUp : faAngleDown} /> {/* Toggle icons */}
    </Button>
  );
};

export default ShowMoreButton;
