import React from 'react'

import CustomInput from './../../../widgets/custom_input'

const initialStates = {
  credits: 3000
}

const ProductCreditsInput = ({ onStateChange, credits }) => {

  const onValueChange = (credits) => {
    onStateChange('credits', credits)
  }

  const onClearButtonClick = () => {
    onValueChange(initialStates.credits)
  }

  return (
    <div className="product-credits-input">
      <label>Enter credits</label>
      <CustomInput
        type="number"
        value={credits}
        onValueChange={onValueChange}
        min={3000}
        max={5000000}
        onClearButtonClick={onClearButtonClick}
      />
    </div>
  )
}

export default ProductCreditsInput