import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import CreatableProductDropdown from './../../../widgets/custom_creatable_dropdown'


const initialStates = {
  dropdown_options: [],
  selected_option: []
}

const CustomAddonDropdownOptions = ({ onStateChange }) => {

  const [state, setState] = useState(initialStates)

  useEffect(() => {
    let opts = state.selected_option
    opts = opts.map(v => v.value)
    onStateChange('dropdown_options', opts)
  }, [state.selected_option, onStateChange])

  useEffect(() => {

  }, [state.dropdown_options])

  const onChangeSelectedOption = (selectedOption) => {
    setState((prevState) => ({
      ...prevState,
      selected_option: selectedOption
    }))
  }

  const onCreateOption = (option) => {
    option = +option
    if (_.isNumber(option) && !_.isNaN(option)) {
      let newOption = { label: option, value: +option }
      setState((prevState) => {
        let opts = prevState.dropdown_options.concat(newOption)

        let prevSelectedOptions = prevState.selected_option.concat(newOption)
        return ({
          ...prevState,
          dropdown_options: opts,
          selected_option: prevSelectedOptions
        })
      })
    }
  }

  const formatCreateLabel = (opt) => (
    <div className="d-flex flex-column">
      <span>{`Create new option: ${opt}`}</span>
      <small className="text-muted">Please enter only numbers</small>
    </div>
  )

  return (
    <div className="currency-dropdown">
      <label>Create custom options for addon</label>
      <CreatableProductDropdown
        options={state.dropdown_options}
        placeholder={'Create custom dropdown options'}
        value={state.selected_option}
        onSelectOption={onChangeSelectedOption}
        onCreateOption={onCreateOption}
        formatCreateLabel={formatCreateLabel}
        isClearable={true}
        isMulti={true}
      />
    </div>
  )
}

export default CustomAddonDropdownOptions