import React, { useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel
} from '@tanstack/react-table';
import { Table as BTable, Form } from 'react-bootstrap';
import TablePagination from '../widgets/pagination';

import tableFormatters from '../tables/user_list/helpers/user_list_formatter';
import * as UserListTableConstnats from '../tables/user_list/helpers/constant';
import * as Constants from '../../helpers/constants';
import LoadingWrapper from '../widgets/loading';

const initialstate = {
  showStatsModal: false,
  selectedUser: {},
  sizePerPage: 50,
  pageIndex: 0,
  pageSize: 50,
  currentPageIndex: 0,
}

const pageSizeOptions = Constants.PAGE_SIZE.values.map((pageSize) => ({
  label: pageSize.toString(),
  value: pageSize,
}))

const CouponsListTable = (props) => {
  const {
    client,
    getClearoutUsers,
    data,
    isLoading,
    sorting,
    searchText,
    tableDataLength,
    dropdowns
  } = props

  const [state, setState] = useState(initialstate)

  const [{ pageIndex, pageSize }, setPagination] =
    useState({
      pageIndex: 0,
      pageSize: state.sizePerPage,
    })

  //options for page sizes
  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  )

  const updateTableData = (pageIndex) => {
    getClearoutUsers(pageIndex * state.sizePerPage, state.sizePerPage, dropdowns, { created_on: 'desc' })
    setState(prevState => ({ ...prevState, currentPageIndex: pageIndex }))
  }

  const ifEmptyData = () => {
    if (data.length === 0) {
      return (
        <tr>
          <td
            colSpan={20}
            className="text-center pt-5"
          >
            <p className="fw-bold">No Records Found</p>
          </td>
        </tr>
      );
    }
  };

  const columns = UserListTableConstnats.COUPON_LIST_TABLE_COLUMNS[client].map(col => {
    return {
      header: col.header,
      accessorKey: col.accessorKey,
      cell: (cell) => tableFormatters[col.cellFormatter](cell, getClearoutUsers, client, dropdowns),
    }
  })

  const onPageChange = (event) => {
    setState(prevState => ({ ...prevState, sizePerPage: event.target.value }))
    table.setPageSize(event.target.value)
    getClearoutUsers(0, event.target.value, searchText.trim(), sorting)
  }

  // const columns = []

  const table = useReactTable({
    data: data,
    columns,
    pageCount: tableDataLength,
    state: {
      sorting,
      pagination
    },
    manualPagination: true,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  })

  return (
    <div>
      <div
        className="user-list-table-container"
      >
        {isLoading ? (
          <div className="loader-container">
            <LoadingWrapper
              type="bars"
              color="#6229e3"
              height={50}
              width={50}
            />
          </div>
        ) : null}
        <BTable
          bordered
          hover
          responsive
          size="sm"
        >
          <thead>
            {table.getHeaderGroups().map((headerGroup, index) => (
              <tr key={`header-${index}`}>
                {headerGroup.headers.map((header, index) => (
                  <th
                    key={`header-${header.id}-${index}`}
                    className={`sticky-cell px-3 ${index === 1 ? 'sticky-on-desktop' : ''}`}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {ifEmptyData()}
            {table.getRowModel().rows.map((row, index) => (
              <tr key={`row-${index}`}>
                {row.getVisibleCells().map((cell, cellIndex) => (
                  <td
                    key={`cell-${index}-${cellIndex}`}
                    className={`sticky-cell ${cellIndex === 1 ? 'sticky-on-desktop' : ''}`}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </BTable>
      </div>
      {/* pagination */}
      <div className="d-flex justify-content-between pt-3">
        <div>
          <Form.Select
            value={table.getState().pagination.pageSize}
            onChange={onPageChange}
          >
            {pageSizeOptions.map((option) => (
              <option
                key={option.value}
                value={option.value}
              >
                {option.label}
              </option>
            ))}
          </Form.Select>
        </div>
        <div>
          <TablePagination
            currentPageIndex={state.currentPageIndex}
            totalpage={Math.min(table.getPageCount(), 5)}
            updateTableData={updateTableData}
          />
        </div>
      </div>
    </div>
  )
}

export default CouponsListTable