import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

export const UserIsAuthenticated = (props) => {
  const { redirectPath = '/', children } = props
  const userIsLoggedIn = useSelector(state => state.user.isLogged)
  const navigate = useNavigate()
  const location = useLocation()
  const searhcParams = new URLSearchParams(location.search)
  const searchRedirect = decodeURIComponent(searhcParams.get('redirect'))

  useEffect(() => {
    if (userIsLoggedIn) {
      navigate(searchRedirect || redirectPath, { replace: true })
    }
  }, [userIsLoggedIn, searchRedirect, redirectPath, navigate])

  return children
}

export const UserIsNotAuthenticated = (props) => {
  let { redirectPath = '/', children } = props
  const userIsLoggedIn = useSelector(state => state.user.isLogged)
  const navigate = useNavigate()
  const location = useLocation()

  if (location.pathname)
    redirectPath = redirectPath + `?redirect=${encodeURIComponent(location.pathname + location.search)}`

  useEffect(() => {
    if (!userIsLoggedIn) {
      navigate(redirectPath, { replace: true })
    }
  }, [userIsLoggedIn, redirectPath, location.pathname, navigate])

  return userIsLoggedIn && children
}