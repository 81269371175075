import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import * as Constants from './../../../../helpers/constants';
import showToastNotification from './../../../widgets/toastify';
import { ApiHelper } from '../../../../helpers/apihelper';

const initialState = {
  reason: 'User Requested',
  userRunningFiles: [],
  isApiCallInProgress: false
};

function DeleteUserModel({ userData, getUserInfo, client, show, onHide }) {
  const [state, setState] = useState(initialState);
  const id = useSelector((state) => state.user.id);

  const handleReasonChange = (e) => {
    setState(prevState => ({ ...prevState, reason: e.target.value }));
  };

  const handleSaveChanges = () => {
    if (state.isApiCallInProgress) {
      return;
    }
    let requestBody = {
      co_user_id: id,
      cop_user_id: userData.user_id,
      user_id: userData.user_id,
      crm_user_id: id,
      reason: state.reason,
      client_secret: Constants.CLIENT_SECRET,
    };

    let body = _.pick(requestBody, ApiHelper(client, 'DELETE_ACCOUNT').API_BODY)
    setState(prevState => ({ ...prevState, isApiCallInProgress: true }));
    ApiHelper(client, 'DELETE_ACCOUNT').API(client, body)
      .then((response) => {
        if (response.status === Constants.CO_API.FAILED) {
          showToastNotification({
            message: 'Failed to Delete user',
            isSuccess: false,
          });
        } else if (response.status === Constants.CO_API.SUCCESS) {
          getUserInfo();
          handleCloseModal()
          showToastNotification({
            message: 'Successfully Deleted user',
            isSuccess: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setState(prevState => ({ ...prevState, isApiCallInProgress: false }));
      });
  };

  // Memoized function to fetch the user statuses
  const handleShowModal = useCallback(() => {
    const requestBody = {
      user_id: userData.user_id,
    };

    let body = _.pick(requestBody, ApiHelper(client, 'GET_USER_STATUS').API_BODY)

    ApiHelper(client, 'GET_USER_STATUS').API(client, body)
      .then((response) => {
        if (response.status === Constants.CO_API.FAILED) {
          console.log('failed');
        } else if (response.status === Constants.CO_API.SUCCESS) {
          const lists = response.data.lists || [];
          setState(prevState => ({ ...prevState, userRunningFiles: lists }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userData.user_id, client]);

  useEffect(() => {
    if (show) {
      handleShowModal();
    }
  }, [show, handleShowModal]); // Include handleShowModal in dependencies


  const handleCloseModal = () => {
    setState(initialState);
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={handleCloseModal}
    >
      <Modal.Header closeButton>
        <Modal.Title className='fs-6 fw-bold'>
          Are You Sure you want to Delete {userData.name || userData.email}&apos;s account?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='modal-body-bg-color'>
        {state.userRunningFiles.length > 0 &&
          <div className="col-md-12 text-danger">
            <label>User has {state.userRunningFiles.length} running lists:</label>
            <ol>
              {state.userRunningFiles.map((map, key) => (
                <li key={key}>{map.details.name} ({map.user_details[0].email}) ({map.action_inprogress.action})</li>
              ))}
            </ol>
          </div>
        }
        <Form>
          <Form.Group className='row'>
            <Form.Label className='col-md-2 py-2'>
              Reason:
            </Form.Label>
            <div className='col-md-10'>
              <Form.Control
                type='text'
                value={state.reason}
                onChange={handleReasonChange}
              />
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='danger'
          onClick={handleSaveChanges}
          disabled={state.isApiCallInProgress}
        >
          Yes!!! Delete Account
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteUserModel;
