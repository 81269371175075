import React, { useEffect, useState, useCallback } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import classnames from 'classnames';

import ApiService from '../../helpers/apiservice';
import * as Constants from '../../helpers/constants';
import * as Helper from '../../helpers/helper';
import showToastNotification from './toastify'

const DownloadModal = ({
  showDownloadModal,
  listDetails,
  closeDownloadModal,
  ...props
}) => {

  const [showModal, setShowModal] = useState(showDownloadModal);
  const [errors, setErrors] = useState({});
  const [validationFilterState, setValidationFilterState] = useState({
    valid: true,
    invalid: true,
    catch_all: true,
    unknown: true
  });
  const [selectedDownloadOption, setSelectedDownloadOption] = useState('guaranteed_deliverable');
  const [showImmDownload, setShowImmDownload] = useState('email_with_std_co_col');
  const [downloadDisabled, setDownloadDisabled] = useState(false);
  const [errorMessageIfNoValueFound, setErrorMessageIfNoValueFound] = useState('');
  const [labelDescription, setLabelDescription] = useState(Constants.DELIVERABLES_GUARANTEED);
  const [deliverablesLabel, setDeliverablesLabel] = useState('Guaranteed Deliverables');

  const [duplicates, setDuplicates] = useState({
    deliverable_duplicates: true,
    non_deliverable_duplicates: true,
    deliverable_with_risk_duplicates: true,
    custom_duplicates: true,
    all_result_duplicates: true,
  });

  const toggleModal = () => {
    setShowModal(!showModal);
    setValidationFilterState({
      valid: true,
      invalid: true,
      catch_all: true,
      unknown: true
    });
    setErrors({});
    setSelectedDownloadOption('guaranteed_deliverable');
    setDownloadDisabled(false);
    setErrorMessageIfNoValueFound('');
    setDuplicates({
      deliverable_duplicates: true,
      non_deliverable_duplicates: true,
      deliverable_with_risk_duplicates: true,
      custom_duplicates: true,
      all_result_duplicates: true,
    });

    closeDownloadModal();
  };

  const onValidationFilterOptsChange = (val) => {
    setValidationFilterState((prevValidationFilter) => ({
      ...prevValidationFilter,
      [val]: !prevValidationFilter[val]
    }));
  };
  
  const validateDownloadSubmit = () => {
    let valid = true;
    let errors = {};

    const validationFilter = Constants.DOWNLOAD_VALIDATION_FILTER.filter((element) => {
      return validationFilterState[element] && listDetails.verification_result[element].value > 0;
    });

    if (selectedDownloadOption === 'custom' && validationFilter.length < 1) {
      valid = false;
      errors['validation_filter_opts'] = 'Please select at least one status filter option';
    }
  
    if (!selectedDownloadOption) {
      valid = false;
      errors['download_selected_option'] = 'Please select at least one option to download file';
    }

    setErrors(errors); // Update the errors state
    return valid;
  };
  
  const downloadResultWithPolling = (body) => {
    let downloadWin;

    ApiService.downloadresultForClearoutList(body)
      .then((res) => {
        if (res.status === Constants.SF_API.Success) {
          if (res.data.download_queue_id) {
            downloadWin = window.open(
              '/download_result/' + Math.floor(Math.random() * 90000) + 10000,
              '_blank'
            );
            downloadWin.location = '/download_result/' + res.data.download_queue_id;
            downloadWin.focus();
            toggleModal(); // Call the toggleModal function directly
          } else if (res.data && res.data.url) {
            downloadWin = window.open('/download/result', '_blank'); // Open in a new tab
            toggleModal(); // Call the toggleModal function directly
            setTimeout(() => {
              downloadWin.location = res.data.url;
              downloadWin.focus();
            }, 1000);
            setTimeout(() => {
              downloadWin.close();
            }, 30000);
          }
        } else {
          showDownloadFailedErrMessage(res); // Call the function directly
        }
      })
      .catch((err) => {
        showDownloadFailedErrMessage(err); // Call the function directly
        console.log('list info api erred', err);
      });
  };

  const downloadResultWithoutPolling = (body) => {
    let downloadWindow;

    ApiService.downloadresultForClearoutList(body)
      .then((res) => {
        if (res.status === Constants.SF_API.Success) {
          if (res.data && res.data.message) {
            downloadWindow = window.open(
              '/download/result?q=true&n=' + Helper.truncateMiddle(listDetails.details.name, 14),
              '_blank'
            );
            toggleModal();
            setTimeout(() => {
              downloadWindow.focus();
            }, 1000);
            setTimeout(() => {
              downloadWindow.close();
            }, 30000);
          } else if (res.data && res.data.url) {
            downloadWindow = window.open('/download/result', '_blank');
            toggleModal();
            setTimeout(() => {
              downloadWindow.location = res.data.url;
              downloadWindow.focus();
            }, 1000);
            setTimeout(() => {
              downloadWindow.close();
            }, 30000);
          }
        } else {
          showDownloadFailedErrMessage(res);
        }
      })
      .catch((err) => {
        showDownloadFailedErrMessage(err);
        toggleModal();
        console.log('list info api erred', err);
      });
  };

  const getDownloadOptions = useCallback((option) => {

    console.log(option)
    let safeToSendExists = false, 
      validationFilter = [], 
      downloadDisabled = false, 
      errorMessageIfNoValueFound = '',
      email_with_risk = 0,
      labelDescription = '',
      deliverablesLabel = 'Guaranteed Deliverables';

    if (listDetails.verification_result && listDetails.verification_result.safe_to_send) {
      safeToSendExists = true;
    }

    const body = {
      list_id: listDetails.list_id,
      co_user_id: listDetails.user_details.user_id,
      user_id: props.user.id,
    };

    switch (option) {
      case 'guaranteed_deliverable':
        if (safeToSendExists) {
          body.options = {
            filters: {
              safe_to_send: 'deliverables',
            },
            name: option,
            is_immediate_download: listDetails.details && listDetails.details.is_immediate_download,
            append_result: true,
            duplicates: false,
          };
          if (listDetails.verification_result && listDetails.verification_result.safe_to_send['yes'].value < 1) {
            downloadDisabled = true;
            errorMessageIfNoValueFound = 'Please select another option, as there are no deliverable email addresses in the list';
          }
          labelDescription = Constants.DELIVERABLES_GUARANTEED;
        } else {
          body.options = {
            filters: {
              status: ['valid'],
            },
            is_immediate_download: listDetails.details && listDetails.details.is_immediate_download,
            name: option,
            append_result: true,
            duplicates: false,
          };
          if (listDetails.verification_result && listDetails.verification_result['valid'].value < 1) {
            downloadDisabled = true;
            errorMessageIfNoValueFound = 'Please select another option, as there are no valid email addresses in this list';
          }
          labelDescription = Constants.DELIVERABLES;
          deliverablesLabel = 'Deliverables';
        }
        break;

      case 'non_deliverable':
        if (safeToSendExists) {
          body.options = {
            filters: {
              safe_to_send: 'non_deliverables',
            },
            is_immediate_download: listDetails.details && listDetails.details.is_immediate_download,
            name: option,
            append_result: true,
            duplicates: false,
          };
          if (listDetails.verification_result && listDetails.verification_result.safe_to_send['no'].value < 1) {
            downloadDisabled = true;
            errorMessageIfNoValueFound = 'Please select another option, as there are no non-deliverable email addresses in the list';
          }
          labelDescription = Constants.NON_DELIVERABLES_SAFE_TO_SEND;
        } else {
          body.options = {
            filters: {
              status: ['invalid'],
            },
            is_immediate_download: listDetails.details && listDetails.details.is_immediate_download,
            name: option,
            append_result: true,
            duplicates: false,
          };
          if (listDetails.verification_result && listDetails.verification_result['invalid'].value < 1) {
            downloadDisabled = true;
            errorMessageIfNoValueFound = 'Please select another option, as there are no invalid email addresses in this list';
          }
          labelDescription = Constants.NON_DELIVERABLES;
        }
        break;

      case 'guaranteed_deliverables_with_risk':
        body.options = {
          filters: {
            safe_to_send: 'deliverables_with_risk',
          },
          is_immediate_download: listDetails.details && listDetails.details.is_immediate_download,
          name: option,
          append_result: true,
          duplicates: false,
        };
        email_with_risk = listDetails.verification_result.safe_to_send['yes'].value + listDetails.verification_result.safe_to_send['risky'].value;
        if (listDetails.verification_result && email_with_risk < 1) {
          downloadDisabled = true;
          errorMessageIfNoValueFound = 'Please select another option, as there are no deliverable with risk email addresses in this list';
        }
        labelDescription = (
          <div>
            <p className="mb-0">
              The result will include Guaranteed Deliverables (mentioned above) and the email addresses that are
              determined risky. The risk factor depends upon multiple reasons like
            </p>
            <ul style={{ paddingLeft: '15px', marginBottom: '0' }}>
              <li>Low deliverability score</li>
              <li>High volume of role-based email addresses</li>
              <li>Any temporary mail account issue</li>
              <li>Mail server configured to accept all email messages </li>
            </ul>
            <p className="mb-0">
              The email addresses of this result file are safe to use when the bounce rate is strictly below 5% or using
              your own email sending platform without relying on an external Email Service Provider (ESP).
            </p>
          </div>
        );
        break;

      case 'custom':
        validationFilter = Constants.DOWNLOAD_VALIDATION_FILTER.filter((element) => {
          return validationFilterState[element] && listDetails.verification_result[element].value > 0;
        });
        body.options = {
          filters: {
            status: validationFilter,
          },
          is_immediate_download: listDetails.details && listDetails.details.is_immediate_download,
          name: option,
          append_result: true,
          duplicates: !duplicates.customDuplicates,
        };
        break;

      case 'email_with_std_co_col':
        body.options = {
          is_immediate_download: listDetails.details && listDetails.details.is_immediate_download,
          name: option,
          append_result: false,
        };
        break;

      default:
        body.options = {
          is_immediate_download: listDetails.details && listDetails.details.is_immediate_download,
          name: option,
          append_result: false,
        };
        break;
    }

    setDownloadDisabled(downloadDisabled);
    setErrorMessageIfNoValueFound(errorMessageIfNoValueFound);
    setLabelDescription(labelDescription);
    setDeliverablesLabel(deliverablesLabel);

    return body;
  }, [listDetails, duplicates.customDuplicates, props.user.id, validationFilterState]);

  useEffect(() => {
    
    let selectedOption = 'guaranteed_deliverable';
    let immDownloadOption = 'email_with_std_co_col';

    if (listDetails?.details?.default_generated_file) {
      selectedOption = listDetails.details.default_generated_file;
      immDownloadOption = listDetails.details.default_generated_file;
    }

    setShowModal(showDownloadModal);
    setSelectedDownloadOption(selectedOption);
    setShowImmDownload(immDownloadOption);

    getDownloadOptions(selectedOption);
    
  }, [showDownloadModal, listDetails, showModal, getDownloadOptions]);

  const onDownloadOptionSelect = (val) => {
    setSelectedDownloadOption(val);
    getDownloadOptions(val);
  };

  const showDownloadFailedErrMessage = (err) => {
    showToastNotification({
      message: err.error ? err.error.message : err.message,
      isSuccess: false,
    });
    toggleModal();
  };

  const onDuplicateSelect = (type) => {
    setDuplicates((prevDuplicates) => ({
      ...prevDuplicates,
      [type]: !prevDuplicates[type], // Toggle the specific duplicate
    }));
  };

  const onDownloadSubmit = () => {
    if (validateDownloadSubmit()) {
      const body = getDownloadOptions(selectedDownloadOption);

      if (listDetails.details.is_immediate_download) {
        downloadResultWithPolling(body);
      } else {
        downloadResultWithoutPolling(body);
      }
    }
  };

  let duplicateDisabled = (listDetails.verification_result && listDetails.verification_result.duplicate && listDetails.verification_result.duplicate.value === 0) ? true : false

  return (
    <Modal
      show={showModal}
      onHide={toggleModal}
      className="download-result-modal"
      size='lg'
    >
      <Modal.Header closeButton>
        <Modal.Title>Download Verified Result</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row style={{ borderBottom: '1px solid #f4f5f7' }}>
          <Col sm={12}>
            <p className="mb-3">
          Please choose how you wish to download the result file for list: 
              <span
                className="file-name"
                title={listDetails.details.name}
              >
                <b>{Helper.truncateMiddle(listDetails.details.name, 14)}</b>
              </span>
            </p>
          </Col>
        </Row>
        <Row className="download-container">
          <Col
            sm={6}
            className="append-checkbox-div"
          >
            <div className="radio">
              <label>
                <input
                  type="radio"
                  checked={selectedDownloadOption === 'guaranteed_deliverable'}
                  name="append"
                  className="append-checkbox"
                  value="guaranteed_deliverable"
                  id="Deliverables"
                  onChange={() => onDownloadOptionSelect('guaranteed_deliverable')}
                />
                {deliverablesLabel}
                {showImmDownload === 'guaranteed_deliverable' ? <b className="all-result-text">(Immediate download)</b> : null}
              </label>
            </div>
            {listDetails.verification_result && listDetails.verification_result.safe_to_send ? (
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    checked={selectedDownloadOption === 'guaranteed_deliverables_with_risk'}
                    name="append"
                    className="append-checkbox"
                    value="guaranteed_deliverables_with_risk"
                    id="non-deliverables"
                    onChange={() => onDownloadOptionSelect('guaranteed_deliverables_with_risk')}
                  />
              Deliverables With Risk
                  {showImmDownload === 'guaranteed_deliverables_with_risk' ? <b className="all-result-text">(Immediate download)</b> : null}
                </label>
              </div>
            ) : null}
            <div className="radio">
              <label>
                <input
                  type="radio"
                  checked={selectedDownloadOption === 'non_deliverable'}
                  name="append"
                  className="append-checkbox"
                  value="non_deliverable"
                  id="non-deliverables"
                  onChange={() => onDownloadOptionSelect('non_deliverable')}
                />
            Non - Deliverables
                {showImmDownload === 'non_deliverable' ? <b className="all-result-text">(Immediate download)</b> : null}
              </label>
            </div>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  checked={selectedDownloadOption === 'email_with_std_co_col'}
                  name="append"
                  className="append-checkbox"
                  value="email_with_std_co_col"
                  id="all-result"
                  onChange={() => onDownloadOptionSelect('email_with_std_co_col')}
                />
            Email addresses with Clearout standard columns
                {showImmDownload === 'email_with_std_co_col' ? <b className="all-result-text">(Immediate download)</b> : null}
              </label>
            </div>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  checked={selectedDownloadOption === 'custom'}
                  name="append"
                  className="append-checkbox"
                  value="custom"
                  id="all-result"
                  onChange={() => onDownloadOptionSelect('custom')}
                />
            Custom
                {showImmDownload === 'custom' ? <b className="all-result-text">(Immediate download)</b> : null}
              </label>
            </div>
          </Col>
          <Col
            sm={6}
            className="download-option-div"
          >
            <div className={classnames('download-option-desc', selectedDownloadOption === 'guaranteed_deliverable' ? 'selected-option' : '')}>
              <h3 className="option-heading">{deliverablesLabel}</h3>
              <div className="option-desc pb-1">{labelDescription}</div>
              <p className={classnames(errorMessageIfNoValueFound ? 'option-desc mt-2 mb-2' : 'd-none')}>
                <b>{errorMessageIfNoValueFound}</b>
              </p>
            </div>
            <div className={classnames('download-option-desc', selectedDownloadOption === 'guaranteed_deliverables_with_risk' ? 'selected-option' : '')}>
              <h3 className="option-heading">Deliverables With Risk</h3>
              <div className="option-desc">{labelDescription}</div>
              <p className={classnames(errorMessageIfNoValueFound ? 'option-desc mt-2 mb-2' : 'd-none')}>
                <b>{errorMessageIfNoValueFound}</b>
              </p>
            </div>
            <div className={classnames('download-option-desc', selectedDownloadOption === 'non_deliverable' ? 'selected-option' : '')}>
              <div>
                <h3 className="option-heading">Non - Deliverables</h3>
                <div className="option-desc">{labelDescription}</div>
                <p className={classnames(errorMessageIfNoValueFound ? 'option-desc mt-2 mb-2' : 'd-none')}>
                  <b>{errorMessageIfNoValueFound}</b>
                </p>
              </div>
            </div>
            <div className={classnames('download-option-desc', selectedDownloadOption === 'email_with_std_co_col' ? 'selected-option' : '')}>
              <h3 className="option-heading">Email addresses with Clearout standard columns</h3>
              <p className="option-desc">
            The result file will include all verified status email addresses – Valid, Invalid, Catch All, Unknown appended to other Clearout columns. Duplicates, if any, will not be included in the result file. Your original file columns will not be included in the result file.
              </p>
            </div>
            <div className={classnames('download-option-desc', selectedDownloadOption === 'custom' ? 'selected-option' : '')}>
              <div>
                <h3 className="option-heading">Custom</h3>
                <p className="option-desc ">
              The result file will include your original list together with the columns you choose further- Valid, Invalid, Catch All and Unknown. Select columns you want to filter by :
                </p>
                <div>
                  {Constants.DOWNLOAD_VALIDATION_FILTER.map((column, index) => {
                    let val = Constants.VALIDATION_FILTERS[column].value;
                    let colValue = listDetails?.verification_result?.[column].value;
                    let total = listDetails?.verification_result?.total;
                    let isDisabled = colValue > 0 ? false : true;
                    let isChecked = !isDisabled ? validationFilterState[val] : false;
                    return (
                      <div
                        className="checkbox"
                        key={index}
                      >
                        <label className="append-checkbox-values">
                          <input
                            className="filter-checkbox-values"
                            type="checkbox"
                            disabled={isDisabled}
                            checked={isChecked}
                            value={val}
                            onChange={() => onValidationFilterOptsChange(val)}
                          />
                          {Constants.VALIDATION_FILTERS[column].name}
                          {total ? (
                            <span className="filter-val">
                          ({colValue} of {total})
                            </span>
                          ) : null}
                        </label>
                      </div>
                    );
                  })}
                </div>
                <label className={classnames(errors['validation_filter_opts'] ? 'd-block error-message' : 'd-none')}>
                  {errors['validation_filter_opts']}
                </label>
                <label className="append-checkbox-values">
                  <input
                    className="filter-checkbox-values"
                    type="checkbox"
                    checked={duplicates.customDuplicates}
                    value={'customDuplicates'}
                    disabled={duplicateDisabled}
                    onChange={() => onDuplicateSelect('customDuplicates')}
                  />
              &nbsp;Remove Duplicates
                </label>
              </div>
              <label className={classnames(errors['validation_filter_opts'] ? 'd-block error-message' : 'd-none')}>
                {errors['validation_filter_opts']}
              </label>
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <label className={classnames(errors['download_selected_option'] ? 'd-block error-message' : 'd-none')}>
            {errors['download_selected_option']}
          </label>
        </div>
        <Button
          className="btn-clearout m-1"
          type="submit"
          onClick={onDownloadSubmit}
          disabled={downloadDisabled}
        >
          <i className={'fa fa-download px-2 '}></i>
      Download Result
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DownloadModal;
