import React, { useState } from 'react'
import _ from 'lodash'

import ProductDropdown from './../../../widgets/custom_dropdown'

const initialState = {
  selectedOption: {}
}

const QuantityDropdown = ({
  product,
  onStateChange,
  dropdownOptions,
}) => {

  const [state, setState] = useState(initialState)

  if (!_.isEmpty(product.dropdown_options))
    initialState.selectedOption = _.first(product.dropdown_options)

  const onQuantityChange = (selectedOption) => {
    setState((prevState) => ({
      ...prevState,
      selectedOption
    }))

    onStateChange('quantity', selectedOption.value)
  }

  return (
    <div className="quantity-dropdown">
      <ProductDropdown
        options={dropdownOptions}
        placeholder={'Select Quantity'}
        value={state.selectedOption}
        onSelectOption={onQuantityChange}
        searchable={false}
      />
    </div>
  )
}

export default QuantityDropdown