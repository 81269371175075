import React, { useState } from 'react'

import CustomCheckOrRadio from './../../../widgets/custom_check'

const initialStates = {
  reset_credits: false
}

const ResetCreditsCustomCheckbox = ({ onStateChange }) => {

  const [state, setState] = useState(initialStates)

  const onValueChange = () => {
    setState((prevState) => ({
      ...prevState,
      reset_credits: !prevState.reset_credits
    }))

    onStateChange('reset_credits', !state.reset_credits)
  }

  return (
    <div className="product-is-custom-check">
      <CustomCheckOrRadio
        type="checkbox"
        checked={state.reset_credits}
        onValueChange={onValueChange}
      />
      <span>Reset Credits</span>
    </div>
  )
}

export default ResetCreditsCustomCheckbox